.button--dashboard {
  font-family: var(--header-font);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  letter-spacing: 1px;
  box-shadow: var(--shadow-mid);
  transition: transform 0.25s ease-in, box-shadow 0.25s ease-in;

  &:focus {
    outline: var(--accent-main) auto 5px;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: var(--shadow-mid-hover);
  }
}

.button--dashboard-small {
  @extend .button--dashboard;
  width: 96px;
  height: 42px;
  padding: 12px 14px;

  @media screen and (max-width: 1140px) {
    width: 80px;
    height: 36px;
    font-size: 14px;
    padding: 4px;
  }
}

.button--dashboard-med {
  @extend .button--dashboard;
  width: 160px;
  height: 42px;

  @media screen and (max-width: 768px) {
    width: 128px;
    height: 36px;
    font-size: 14px;
    padding: 4px;
  }
}

.button--dashboard-full_width {
  @extend .button--dashboard;
  width: 100%;
}

.button--dashboard-full_width-reset {
  @extend .button--dashboard;
  max-width: 100%;
}

.button--dashboard-primary {
  color: var(--font-color-light);
  background-color: var(--accent-main);
  transition: transform 0.25s ease-in, box-shadow 0.25s ease-in;
}

.button--dashboard-primary-disabled {
  color: var(--font-color-dark);
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--font-color-dark);
  transition: transform 0.25s ease-in, box-shadow 0.25s ease-in;
}

.button--dashboard-secondary {
  color: var(--font-color-dark);
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--font-color-dark);
  transition: transform 0.25s ease-in, box-shadow 0.25s ease-in;
}

.button--dashboard-warning {
  color: var(--font-color-alert);
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--font-color-alert);
  transition: transform 0.25s ease-in, box-shadow 0.25s ease-in;
}

.button--dashboard-warning_no_border {
  @extend .button--dashboard-warning;
  border: none;
  box-shadow: none;
}

.button--dashboard-secondary_no_border {
  @extend .button--dashboard-secondary;
  border: none;
  box-shadow: none;
  padding: 0;
  width: 130px;
  justify-self: center;
  transition: color 0.25s ease-in;

  &:hover {
    transform: translateY(0px);
    box-shadow: none;
    color: var(--accent-main);
  }
}

.button--inline_text_button {
  color: var(--accent-main);
  background-color: rgba(0, 0, 0, 0);
}

.button--mobile_nav_button {
  font-size: 24px;
  padding: 16px;
  margin: 16px;
}

.button--mobile_nav:focus {
  outline: none;
}

.button--dashboard-secondary_no_border_wide {
  @extend .button--dashboard-secondary_no_border;
  width: 100%;
}

.button--modal_cancel {
  border: 0;
  width: 2rem;
  height: 2rem;
  padding: 0 !important;
  font-size: 1.3rem !important;
  border-radius: 0 !important;
  background: inherit;
  color: #b39d9d;
  margin-left: 20rem;
}

.button--modal_cancel_application_declined {
  display: block;
  width: 50px;
  height: 50px;
  padding: 10px;
  font-size: 35px;
  border-radius: 0;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-weight: 600;
  color: rgba(0, 27, 17, 0.2);
}

.button--modal_cancelJob {
  min-width: unset !important;
  padding: 0 !important;
  font-size: 48px !important;
  border-radius: 0 !important;
}

// Text button at bottom of account modals
.button--modal_change {
  font-family: var(--header-font);
  color: var(--accent-main);
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: var(--accent-main) auto 5px;
  }
}

.button--modal_change-password_reset {
  @extend .button--modal_change;
  color: black;
  font-weight: 100;
  padding: 0;
  font-size: 14px;
}

.button--toggle-auth {
  margin: 0 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 108px;
  height: 40px;
  right: 127px;
  top: calc(50% - 40px / 2);

  background: #2eb790;
  border-radius: 24px;
  .signup {
    margin: auto;
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
  }
}

.button--toggle-auth-secondary {
  margin: 0 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-style: solid;
  border-color: #2eb790;
  border-width: 2px;
  width: 108px;
  height: 40px;
  right: 127px;
  top: calc(50% - 40px / 2);

  background: white;
  border-radius: 24px;
  .login {
    margin: auto;
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #2eb790;
  }
}

.workIconCareer {
  font-size: small;
  padding-left: 30px;
  top: 25px;
}

.helpDocBtn {
  padding-left: 15rem;
  padding-bottom: 5rem;
  @media screen and (max-width: 768px) {
    padding-left: 2rem;
    padding-bottom: 3rem;
  }
}

.oauthbutton {
  transition:0.1s;
}

.oauthbutton:hover {
  transition:0.1s;
  transform:scale(1.2);
}