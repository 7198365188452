:root {
  //* Colors - Light to Dark

  // Primary Colors
  // actions, primary links, nav, icons, accent borders, special text
  --font-color-light: #eefcf5;
  --font-color-light-two: rgba(0, 27, 17, 0.7);
  --accent-main: #2eb790;
  --font-color-dark-p: #014d40;
  --accent-new-main: #2eb790;
  // Neutral Colors
  // text, backgrounds, borders, secondary buttons and links
  --bg-white: #FFFFFF;
  --bg-white-transparent: rgba(241, 245, 248, 0.65);
  --bg-lightblue: #dae2ec;
  --bg-lightbluetwo: #819ab0;
  --bg-lightbluethree: #48647f;
  --bg-blue: #324d66;
  --bg-darkblue: #21354a;
  --bg-darkestblue: #102a42;
  --bg-green: #014b3f;

  --font-color-gray: #48647f;
  --font-color-dark: #102a42;

  // Accents
  // elements that need to stand out, errors, positive trends
  // --font-color-alert: #eb6767;
  --font-color-alert: #d64545;
  --font-color-error: #e66a6a;

  // Shadows
  --shadow-color-light: rgba(24, 24, 24, 0.1);
  --shadow-color-strong: rgba(24, 24, 24, 0.2);

  --shadow-low: 1px 1px 2px 0px var(--shadow-color-strong),
    1px 2px 2px 0px var(--shadow-color-light);
  --shadow-mid: 2px 2px 5px 0px var(--shadow-color-strong),
    2px 4px 5px 0px var(--shadow-color-light);
  --shadow-mid-hover: 3px 3px 6px 0px var(--shadow-color-strong),
    3px 5px 6px 0px var(--shadow-color-light);
  --shadow-high: 0px 2px 12px 0px var(--shadow-color-strong),
    0px 4px 17px 2px var(--shadow-color-light);
  --shadow-inset: inset 0px 0px 5px 2px var(--shadow-color-strong);

  // Padding
  --page-spacing: 0 16px;
}
