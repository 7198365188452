.container--mobile_nav_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafbfd;
}

.container--mobile_nav {
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  align-items: baseline;
  height: 100%;
  box-sizing: border-box;
}

.button--mobile_nav {
  justify-self: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);
}

.container--nav_button_line {
  width: 30px;
  height: 5px;
  background-color: var(--bg-white);
  color: rgba(0, 0, 0, 0);
  margin: 2px 0;
  border-radius: 5px;
}

.container--inner_mobile_nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-white);
}

.headerSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
