.search_wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: fit-content !important;
  }

}
.container--no_results{
  display: flex;

  width:100%;
  align-items: center;
  justify-content: center;
}

.add-Member-Wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
}

.search_input--wrapper {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100% !important;
  }
}

.input--search_input {
  box-sizing: border-box;
  display: block;
  width: 24em;
  height: 48px;
  padding: 4px 8px;
  font-size: 16px;
  border: 1px solid #e0e0e0;

  -webkit-appearance: none;

  @media screen and (max-width: 768px) {
    width: 100% !important;
    font-size: 14px;
    padding: 0px 8px;
  }
}

.input--skill{
  width: 100% !important;
  font-size: 14px;
  padding: 0px 8px;
}

.input--search {
  text-shadow: 0 0 10px var(--font-color-dark);
  border-radius: 0 5px 5px 0 !important;
  font-size: 16px;
  font-family: var(--header-font);
  font-weight: 500;
  padding: 4px 24px;
  height: 48px;
  box-shadow: var(--shadow-mid);
  color: white !important;
  background-color: #2eb790 !important
}


.search-select {
  box-shadow: var(--shadow-mid);
  background-color: #2eb790;
  z-index: 2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
