.container--dashboard_page_lists {
  display: grid;
  grid-template: auto auto 1fr / 1fr;
  align-items: start;
  justify-items: center;
}

.container--dashboard_section {
  width: 100%;
  height: 100%;
}

.container--team_list-one,
.container--team_list-two,
.container--team_list-three {
  //margin-bottom: 32px;

  // .MuiGrid-container {
  //   @media screen and (max-width: 1140px) {
  //     display: flex;
  //     justify-content: center;
  //   }
  // }
  @media screen and (max-width: 760px) {
    margin-top: 1rem;
    max-height: 650px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.public-container--team_list-one {
  background-color: red;
}

.header-left {
  text-align: left;
  margin: 30px 0 30px 0;
  color: var(--font-color-dark);
}

.header--no_teams_message {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-color-gray);
  margin: 0;
  grid-column: 1 / 3;
}

.container--team_list {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1075px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}

.container--team_list-empty {
  @extend .container--team_list;
  background-color: var(--bg-white);
  padding: 0;
  box-shadow: none;
}

.container--team_card {
  border-radius: 5px;
  box-shadow: var(--shadow-low);
}

.button--dashboard_team {
  background-color: var(--bg-white);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.container--team_wrapper {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.5fr 1fr;
  padding: 16px;
  border-top: 12px solid var(--accent-main);
  min-height: 256px;
}

.container--create_team_wrapper {
  @extend .container--team_wrapper;
  grid-template: auto auto / 1fr;
  justify-items: center;
  row-gap: 16px;
}

.container--create_team_box-top {
  align-self: end;
}

.container--create_team_box-bottom {
  align-self: start;
}

.text--team_credits_left {
  color: var(--font-color-dark);
  font-size: 14px;
}

.container--team_item_top {
  width: 100%;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  display: grid;
  grid-template: auto 1fr 1fr / 1fr;
  justify-items: left;
  align-items: center;
  row-gap: 6px;
  border-bottom: 1px solid var(--bg-lightblue);
}

.header--team_name {
  align-self: end;
  font-size: 24px;
  font-weight: 700;
  color: var(--font-color-dark);
}

.header--team_name-centered {
  @extend .header--team_name;
  text-align: center;
}

.text--team_manager {
  align-self: start;
  color: var(--font-color-dark);
  font-size: 14px;
  margin: 0;
  font-weight: 100;
  padding-bottom: 16px;
}

.text--team_message {
  color: var(--font-color-light);
  text-shadow: 0px 0px 2px var(--font-color-dark-p);
  background-color: var(--accent-main);
  border: 1px solid var(--accent-main);
  border-radius: 5px;
  font-size: 14px;
  margin: 0 0 4px 0;
  font-weight: 500;
  padding: 4px;
}

.span--team_manager_name {
  font-family: var(--header-font);
  font-size: 14px;
  font-weight: 400;
}

.container--team_item_bottom {
  padding-top: 8px;
  width: 100%;
  grid-row: 2 / -1;
  display: grid;
  grid-template: 1fr 1fr / 1fr;
  row-gap: 4px;
  justify-items: center;
  align-items: center;
}

.container--team_info_one,
.container--team_info_two {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 4px;
  align-items: center;
  justify-items: left;
}

.text--team_members {
  border-radius: 5px;
  margin: 0;
  grid-column: 2 / -1;
  font-size: 16px;
  color: var(--font-color-dark);
}

.text--team_status {
  margin: 0;
  grid-column: 2 / -1;
  font-size: 16px;
  color: var(--font-color-dark);
}

.svg--dashboard_icon {
  grid-column: 1 / 2;
  width: 30px;
  height: 30px;
  opacity: 0.75;
}

.create_new_team_icon {
  @extend .svg--dashboard_icon;
  width: 50px;
  height: 50px;
}

.path--status_icon_path_one,
.path--group_icon_path_one {
  fill: var(--bg-lightblue);
  stroke: var(--bg-lightbluetwo);
}

.path--status_icon_path_two,
.path--group_icon_path_two {
  fill: var(--bg-lightbluetwo);
  stroke: var(--bg-lightbluethree);
}

.container--invites {
  display: flex;
  justify-content: flex-start;
  grid-gap: 30px;
  margin: 8px;
  flex-wrap: wrap;

  @media screen and (max-width: 760px) {
    justify-content: center;
    margin-top: 1rem;
    max-height: 650px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.invites_received,
.invites_sent {
  margin-bottom: 32px;
}

.container--invite_card_wrapper {
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  align-items: center;

  @media screen and (max-width: 768px) {
    grid-template: 1fr / 1fr 1fr;
  }

  @media screen and (max-width: 500px) {
    grid-template: 1fr auto / 1fr;
  }
}

.container--invite_card_left-received {
  @extend .container--team_wrapper;
  background-color: var(--bg-white);
  z-index: 5;
  grid-template: 3fr 2fr / 1fr;
  box-shadow: 1px 1px 2px 0px var(--shadow-color-strong);
  box-sizing: border-box;
  height: 100%;

  @media screen and (max-width: 768px) {
    grid-template: 3fr 1fr / 1fr;
  }
}

.container--invite_card_top-received {
  @extend .container--team_item_top;
  width: 100%;
  height: 100%;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  grid-template: auto auto auto auto auto / 1fr;
  display: grid;
  box-sizing: border-box;
}

.container--app_card_top-received {
  @extend .container--team_item_top;
  grid-column: 1 / 2;
  grid-template: auto auto auto auto auto 1fr / 1fr;
}

.container--invite_card_bottom-received {
  width: 100%;
  height: 100%;
  grid-row: 2 / -1;
  grid-column: 1 / 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 0;
}

.container--invite_card_side-received {
  grid-column: 2 / -1;
  grid-row: 1 / -1;
  display: grid;
  justify-items: left;
  align-items: center;
  background-color: var(--bg-white);
  border-radius: 0px 5px 5px 0px;
  padding: 16px;
  height: 202px;
  row-gap: 8px;
  grid-template: auto 1fr auto / 1fr;
  align-items: start;

  @media screen and (max-width: 768px) {
    grid-column: 1 / 2;
    grid-row: 2 / -1;
  }
}

.container--app_card_side-received {
  @extend .container--invite_card_side-received;
  grid-template: auto 1fr auto / 1fr;
  align-items: start;
}

.container--app_sent_button_wrapper {
  grid-row: 1 / -1;
  grid-column: 3 / -1;
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  width: 100%;
}

.app_button_start {
  grid-column: 1 / 2;
  justify-self: start;
}

.app_button_end {
  grid-column: 3 / -1;
  justify-self: end;
  text-align: right;
}

.header--team_invite_data {
  font-size: 14px;
  font-weight: 100;
  margin: 0;
}

.text--team_invite_data {
  line-height: 1.2;
  font-size: 16px;
  margin: 0;
}

.container--invite_sent_row {
  padding: 8px 16px;
  border-left: 12px solid var(--accent-main);
  background-color: var(--bg-white);
  border-radius: 5px;
  grid-column: 1 / -1;
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  justify-items: left;
  align-items: center;
  row-gap: 4px;
  box-shadow: var(--shadow-low);
}

.container--invite_sent_button_wrapper {
  grid-row: 1 / -1;
  grid-column: 3 / -1;
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  width: 100%;
}

.container--app_sent_row {
  width: 207px;
  height: 240px;
  border: 1px solid rgba(0, 27, 17, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.clickable-app_sent {
    cursor: pointer;
  }

  &.clickable-app_sent:hover {
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.04);
  }
}

.card_header_orange {
  width: 101%;
  margin: -1px;
  background-color: #f28a4b;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--header-font);
}

.card_header_red {
  width: 101%;
  margin: -1px;
  background-color: #e4475b;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--header-font);
}

.header--invite_data {
  font-size: 14px;
  font-weight: 100;
  grid-row: 1 / 2;
  margin: 0;
}

.text--invite_data {
  font-family: var(--notfound-font);
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  grid-row: 2 / -1;
  margin: 0;
  padding-top: 5px;
}

// Dashboard stats styles

.container--page_top_stats {
  display: grid;
  grid-template: 1fr / 0.5fr 1fr 1fr;
  justify-items: end;
  align-items: center;
  column-gap: 16px;
}

.container--page_top_stat_wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: 1fr / 3fr 1fr;
  justify-items: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: var(--shadow-low);
}

.header--stat_heading {
  border-radius: 5px 0 0 5px;
  width: 100%;
  height: 100%;
  background-color: var(--accent-main);
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
  font-size: 18px;
  color: var(--font-color-light);
  text-align: center;
}

.text--stat_value {
  @extend .header--stat_heading;
  background-color: var(--bg-white);
  color: var(--font-color-dark);
  border-radius: 0 5px 5px 0;
  text-align: center;
  box-shadow: inset 0px 0px 5px 2px var(--shadow-color-strong);
}

.stat_teams {
  grid-column: 2 / 3;
}

.stat_reviews {
  grid-column: 3 / -1;
}

#public-tab-panel {
  display: none;
}

.shimmer {
  display: inline-block;
  position: relative;
  right: 37px;
  bottom: 10px;
  color: #007a90;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 600;
  border: solid 1px white;
  padding: 3px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.2);
  background: #e7e7e7 -webkit-gradient(linear, 100% 0, 0 0, from(#acacac), color-stop(0.5, #ffffff), to(#acacac));
  background-position: -4rem top; /*50px*/
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: shimmer;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  background-size: 4rem 100%; /*50px*/
}

@keyframes shimmer {
  0% {
    background-position: -4rem top;
  }

  90% {
    background-position: 12.5rem top; /*200px*/
  }

  100% {
    background-position: 12.5rem top; /*200px*/
  }
}
