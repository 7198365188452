.container--rating_ui {
  position: relative;
  height: 64px;
  width: 100%;
  display: grid;
  grid-template: auto 1fr / repeat(10, 1fr);
  align-items: center;
  justify-items: center;
  column-gap: 8px;
  margin-bottom: 24px;
}

.label--rating {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 12px;
  justify-items: center;
  align-items: center;
  grid-row: 2 / -1;
  font-size: 24px;
  color: var(--font-color-light);
}

.input--rating {
  grid-row: 1 / 2;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked + .container--custom_radio {
    background: var(--accent-main);
    box-shadow: inset 0px 0px 3px 1px var(--bg-darkestblue);
  }
}

.container--custom_radio {
  height: 25px;
  width: 25px;
  border: 2px solid var(--bg-white);
  border-radius: 50%;
  background-color: var(--bg-blue);
}

.container--style_line-checked {
  background: var(--accent-main);
  box-shadow: inset 0px 0px 3px 1px var(--bg-darkestblue);
}

.container--style_line {
  position: absolute;
  height: 2px;
  width: 95%;
  top: 13px;
  background: var(--bg-white);
}

// Rating section on profile page
.container--user_profile_ratings {
  display: grid;
  grid-gap: 30px;
  grid-template: 1fr / 1fr 1fr;
  align-items: center;
  justify-items: center;
  margin: 0 0 2rem 0;

  @media screen and (max-width: 768px) {
    grid-template: 1fr 1fr / 1fr;
    row-gap: 2rem;
  }
}

.header--profile_rating_teamRed {
  height: 4px;
  position: relative;
  bottom: 30px;
  width: 99%;
  opacity: 0.8;
  margin-top: 1.3rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

// .header--profile_rating_youGreen {
//   height: 3px;
//   position: relative;
//   bottom: 30px;
//   width: 98%;
//   opacity: 0.8;
// }

.container--user_profile_rating {
  display: grid;
  width: 100%;
  align-items: center;
  justify-items: center;
  padding: 24px 0;
  border: 1px solid rgba(0, 27, 17, 0.1);
  border-radius: 4px;
  box-shadow: none;
  background-color: white;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.ratings--user_team_avg {
  text-align: center;
  margin-top: 5px !important;
  font-size: 18px !important;
  font-style: italic !important;
  font-weight: 600 !important;
}

.ratings--container {
  display: flex;
  align-items: center;
}

.user_total_rating_team{
  font-size: 17px;
  font-weight: 700;
  color: black;
  margin-top: 5px;
}

.average_rating_one {
  grid-column: 1 / 2;
  box-shadow: 0px 12px 26px -32px #283136;

  @media screen and (max-width: 768px) {
    grid-column: 1 / -2;
    grid-row: 1 / 2;
  }
}

.average_rating_two {
  grid-column: 2 / -1;
  box-shadow: 0px 12px 26px -32px #283136;

  @media screen and (max-width: 768px) {
    grid-column: 1 / -1;
    grid-row: 2 / -1;
  }
}

.header--profile_rating_header {
  font-size: 18px;
  font-weight: 600;
  color: var(--font-color-dark);
  text-align: center;
  margin: 0 0 12px 0;
}

.new_review-header {
  font-size: 18px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.text--user_profile_rating {
  font-family: var(--header-font);
  color: var(--font-color-dark);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: rgba(0, 27, 17, 0.7);
}

.container--user_profile_reviews_wrapper {
  width: 100%;
  margin: 0 auto;
  min-width: 250px;
  min-height: 221px;
  background-color: #fff;
  border: 1px solid rgba(0, 27, 17, 0.1);
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 12px 26px -32px #283136;
}

.container--user_profile_reviews {
  margin: 16px 0 0 10px;
  padding: 5px;
}

.first-review_profile {
  padding: 10px 0;
}

.see-all-reviews_profile {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;

  button {
    background-color: inherit;
    border: none;
    text-decoration: underline;
    font-size: 16px;
    color: #2eb790;
    cursor: pointer;
    z-index: 2;
  }
}

.additional-review_profile_hidden {
  opacity: 0;
  transition: all 0.4s ease;
  max-height: 0;
}

.additional-review_profile {
  transition: all 0.4s ease;
  opacity: 1;
}

.container--user_profile_review {
  box-sizing: border-box;
  display: block;
  max-width: fit-content;
  border-radius: 5px;
  padding: 0 16px;
}

.container--user_profile_review-no_background_color {
  @extend .container--user_profile_review;
  background-color: var(--bg-white);
  padding: 0;
  border: none;
  box-shadow: none;
}

.text--user_unnamed_author {
  color: var(--font-color-dark);
  opacity: 0.49;
  margin-top: 5px;
  font-style: italic;
}

.text--user_review {
  margin: 12px 0 12px 0;
  font-size: 17px;
  font-style: italic;
  text-align: left;
  line-height: 1.3;
  color: var(--font-color-dark) !important;
  width: 600px;
  word-wrap: break-word !important;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    width: 100%;
  }
}

.header--section_title {
  font-size: 25px;
  font-weight: 400;
  color: var(--font-color-dark);
  margin-left: 5px;
  margin-top: 5px;
}
