@mixin taglineTop(
  $font: unset,
  $fontM: unset,
  $width: unset,
  $maxWidth: unset,
  $widthM: 100%
) {
  font-size: $font;
  padding-bottom: 3px;
  @media screen and (max-width: 500px) {
    font-size: $fontM;
    width: $widthM;
    max-width: $maxWidth;
  }
}
@mixin taglineBot(
  $font: unset,
  $textAlign: unset,
  $fontM: unset,
  $maxWidth: unset
) {
  font-size: $font;
  padding-bottom: 3px;
  @media screen and (max-width: 500px) {
    font-size: $fontM;
    width: 100%;
    max-width: $maxWidth;
  }
}

@mixin headTextLandingContainer($width: unset, $fontM: unset) {
  width: $width;
  text-align: center;
  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: $fontM;
  }
}

@mixin signInFormWrap() {
  max-width: 60vw;
  padding-top: 10px;
  width: 100%;

  @media screen and (max-width: 500px) {
    padding: 0 16px;
  }
}

@mixin signInForms() {
  width: 100%;
  padding: 7px;
  @media screen and (max-width: 500px) {
    padding: 0 16px;
  }
}

@mixin joinNowBtnWrapper() {
  margin: 0 auto;
  margin-bottom: 17px;
  width: auto;
  padding-top: 20px;
}

@mixin contWrap() {
  margin: 0 auto;
  padding-bottom: 10px;
  padding-left: 60px;
  padding-right: 60px;
}

@mixin contDiv() {
  height: 20px;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  top: 5px;
  width: 180px;
}

@mixin continueWithLanding() {
  font-size: 14px;
  color: #001b11b7;
  margin: 0 auto;
}

@mixin landing--taglineFirst-M($font: unset, $fontM: unset) {
  padding-top: 100px;
  font-size: $font;
  padding-bottom: 3px;
  width: 100%;
  @media screen and (max-width: 500px) {
    font-size: $fontM;
  }
}

@mixin headTextLandingContainer-M($maxWidth: unset) {
  text-align: center;
  max-width: $maxWidth;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

@mixin headTextLanding-M() {
  padding-top: 5px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

@mixin signInFormWrap-M($padding: unset) {
  width: 100%;
  padding: $padding;
  max-width: 80vw;
  @media screen and (max-width: 500px) {
    padding: 0 16px;
    max-width: 90vw;
  }
}
@mixin headTextLanding() {
  padding: 5px 0px 5px 22px;
  width: 347px;
  font-size: 18px;
  margin: 1px;
  text-align: center;
  color: #001b11b7;
  @media screen and (max-width: 500px) {
    font-size: 16px;
    width: 100%;
  }
}

@mixin signInForm-M() {
  padding: 10px;
  width: 70vw;
  margin: 0 auto;
}
@mixin joinNowBtnWrapper-M() {
  margin-bottom: 15px;
  width: 70vw;
  margin: 0 auto;
  max-width: 50vw;
}

@mixin contDiv-M() {
  height: 20px;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  top: 5px;
  width: 180px;
}

@mixin continueWithLanding-M {
  font-size: 14px;
  color: #001b11b7;
}

/////////////////////
.normalize--landing_hero {
  display: flex;
  min-height: fit-content;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 4.3rem 0;
  @media screen and (max-width: 1280px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    height: auto;
  }
  @media screen and (max-width: 760px) {
    padding-top: 6rem;
    height: auto;
  }
  @media screen and (max-width: 1279px) {
    padding-bottom: 0rem;
  }
}

.layout-container {
  width: 1170px;
  padding: 3rem 0;
  height: 521.391px;
  @media screen and (max-width: 1279px) {
    height: auto;
  }
  @media screen and (max-width: 760px) {
    display: flex;
    width: 100vw;
    padding: 0;

  }
  @media screen and (max-width: 500px) {
    padding-top: 30px;
    height: auto;
  }
}

.contWrap {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
}

.pushBot {
  padding-bottom: 195px;
}

.joinNowLanding {
  color: #f8c22b;
  width: 100%;
  border-radius: 4px !important;
  margin: 20px auto;
  text-transform: none !important;
  font-size: 16px !important;
  //pending
  // border-radius: 8px !important;
  letter-spacing: 1px;

  @media screen and (max-width: 500px) {
    padding: 0 16px;
  }
}

.hTLBold {
  font-size: 18px;
  margin: 1px;
  color: #001b11b7;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}

// top right bottom left css margin order

///////////////////// Control Hero.js FIGMA 000 CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC

.landingTaglineTop000 {
  @include taglineTop($font: 30px, $width: 405px, $fontM: 25px);
}

.landingTaglineBot000 {
  @include taglineBot($font: 30px, $fontM: 25px);
}

/////////////////////  HeroV1.js FIGMA 100 1111111111111111111111111111111111111111111111

.landingTaglineTop100 {
  @include taglineTop($font: 30px, $fontM: 23px);
}

.landingTaglineBot100 {
  @include taglineBot($font: 30px, $fontM: 25px);
}

.headTextLandingContainer100 {
  @include headTextLandingContainer($width: 400px);
}

.headTextLanding {
  font-size: 18px;
  text-align: center;
  color: #001b11b7;
  @media screen and (max-width: 500px) {
    font-size: 12px;
    padding: 0 0.5rem;
  }
}

.hTLBold100 {
  font-size: 18px;
  margin: 1px;
  color: #001b11b7;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}

.landingButtonWrapper100 {
  margin-left: 70px;
  @media screen and (max-width: 500px) {
    margin-left: 0px;
    flex-direction: row;
  }
}

/////////////////////  HeroV2.js FIGMA 010 222222222222222222222222222222222222222222222222222222222

.landingTaglineTop010 {
  @include taglineTop($font: 30px, $fontM: 25px, $width: 405px);
  padding-top: 100px;
}

.landingTaglineBot010 {
  @include taglineBot($font: 30px, $fontM: 25px);
}

.landingButtonWrapper010 {
  padding-left: 33px;
  @media screen and (max-width: 500px) {
    padding-left: 0px;
  }
}

/////////////////////  HeroV3.js FIGMA 110  3333333333333333333333333333333333333333333333333333333333

.landingTaglineTop110 {
  @include taglineTop($font: 30px, $fontM: 24px, $width: 405px);
}

.landingTaglineTop {
  @include taglineTop($font: 30px, $fontM: 24px, $width: 405px);
  font-size: 30px;
  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 24px;
  }
}

.landingTaglineBot110 {
  @include taglineBot($font: 30px, $fontM: 25px);
}

.headTextLandingContainer110 {
  @include headTextLandingContainer($width: 400px, $fontM: 12px);
}

.headTextLandingContainer {
  width: 400px;
  text-align: center;
  @media screen and (max-width: 500px) {
    font-size: 12px;
    width: 100%;
  }
}

.heroContainer {
  max-width: 1170px;
  margin: 0 auto;
  padding: 150px 16px 0 16px;
}

// }
/////////////////////  Hero.js 8 FIGMA 002 4444444444444444444444444444444444444444444
.landing--taglineTop-002 {
  @include taglineTop(
    $font: 32px,
    $fontM: 28px,
    $width: 405px,
    $widthM: auto,
    $maxWidth: auto
  );
  text-align: center;
}

.landing--title {
  font-family: Poppins;
  font-weight: bold;
  line-height: 50px;
  letter-spacing: 0.15px;
  font-size: 32px;
  padding-bottom: 3px;
  margin: 0;
  @media screen and (max-width: 500px) {
    max-width: 100vw;
    font-size: 28px;
    text-align: center;
    padding: 0px 0px 0px 0px;
  }
}

.landing--taglineBot-002 {
  @include taglineBot(
    $font: 32px,
    $fontM: 25px,
    $maxWidth: 100vw,

    $textAlign: center
  );
}

.headTextLandingContainer-002 {
  @include headTextLandingContainer();
}

.signInFormWrap002 {
  @include signInFormWrap();
}
.signInForms-V8 {
  @include signInForms();
}

.signInForms002 {
  @include signInForms();
}

.joinNowBtnWrapper002 {
  @include joinNowBtnWrapper();
}
.contWrap002 {
  @include contWrap();
}

.contDiv002 {
  @include contDiv();
}

.continueWithLanding002 {
  @include continueWithLanding();
}

.landing--taglineFirst002-M {
  @include landing--taglineFirst-M($font: 30px, $fontM: 23px);
}

.landing--taglineSecond002-M {
  font-size: 30px;
  right: 20px;
  padding-bottom: 3px;
}
.headTextLandingContainer002-M {
  @include headTextLandingContainer-M();
}

.headTextLanding002 {
  text-align: center;
}

.headTextLanding002-M {
  @include headTextLanding-M();
}

.signInFormWrap002-M {
  @include signInFormWrap-M($padding: 10px);
}

.signInForm002-M {
  @include signInForm-M();
}
.joinNowBtnWrapper002-M {
  @include joinNowBtnWrapper-M();
}
.contWrap002-M {
  padding: 0 10px 10px 10px;
}

.contDiv002-M {
  @include contDiv-M();
}

.continueWithLanding002-M {
  @include continueWithLanding-M();
}

/////////////////////  Hero.js 9 FIGMA 102 4444444444444444444444444444444444444444444
.landing--taglineTop-102 {
  @include taglineTop($font: 32px, $width: 405px);
}
.landing--taglineBot-102 {
  @include taglineBot($font: 32px);
}

.headTextLandingContainer-102 {
  @include headTextLanding();
}

.signInFormWrap102 {
  @include signInFormWrap();
}
.signInForms102 {
  @include signInForms();
}

.joinNowBtnWrapper102 {
  @include joinNowBtnWrapper();
}
.contWrap102 {
  @include contWrap();
}

.contDiv102 {
  @include contDiv();
}

.continueWithLanding102 {
  @include continueWithLanding();
}

.landing--taglineFirst102-M {
  @include landing--taglineFirst-M($font: 30px);
}

.landing--taglineSecond102-M {
  font-size: 30px;
  right: 20px;
  padding-bottom: 3px;
}
.headTextLandingContainer102-M {
  @include headTextLandingContainer-M();
}

.headTextLanding102-M {
  @include headTextLanding-M();
}

.signInFormWrap102-M {
  @include signInFormWrap-M($padding: 0px 0px 0px 0px);
}

.signInForm102-M {
  @include signInForm-M();
}
.joinNowBtnWrapper102-M {
  @include joinNowBtnWrapper-M();
}
.contWrap102-M {
  padding: 0px auto 1rem auto;
}

.contDiv102-M {
  @include contDiv();
  padding-bottom: 20px;
}

.continueWithLanding102-M {
  @include continueWithLanding();
}
.headTextLandingContainer102 {
  @include headTextLandingContainer();
}

/////////////////////  Hero.js 10 FIGMA 012 4444444444444444444444444444444444444444444
.landing--taglineTop-012 {
  @include taglineTop($font: 32px, $fontM: 28px, $maxWidth: 100%);
}
.landing--taglineBot-012 {
  @include taglineBot($font: 32px, $fontM: 28px, $maxWidth: 100%);
}

.headTextLandingContainer-012 {
  @include headTextLandingContainer();
}

.signInFormWrap012 {
  @include signInFormWrap();
}
.signInForms-V8 {
  @include signInForms();
}

.signInForms012 {
  @include signInForms();
}

.joinNowBtnWrapper012 {
  @include joinNowBtnWrapper();
}
.joinNowBtnWrapper012-M {
  @include joinNowBtnWrapper-M();
}
.contWrap012 {
  @include contWrap();
}

.contDiv012 {
  @include contDiv();
}

.continueWithLanding012 {
  @include continueWithLanding();
}

.landing--taglineFirst012-M {
  @include landing--taglineFirst-M($font: 23px);
}

.headTextLandingContainer012-M {
  @include headTextLandingContainer-M($maxWidth: 355px);
}

.loadingPicture {
  width: 700px !important;
  height: 527.5px !important;
  max-width: 100vw;
  @media screen and (max-width: 760px) {
    width: 500px !important;
    height: auto !important;
  }
}

.loadingAnimation {
  width: 700px !important;
  height: 527.5px !important;
  @media screen and (max-width: 760px) {
    width: 80vw !important;
    height: 32.745vh !important;
  }
  @media screen and (max-width: 501px) {
    width: 100vw !important;
    height: 37vh !important;

  }
}

.headTextLanding012-M {
  padding-top: 5px;
}

.signInFormWrap012-M {
  @include signInFormWrap-M($padding: 1rem);
}
.headTextLanding102 {
  @include headTextLanding();
}

.signInForm012-M {
  margin: auto;
  @include signInForm-M();
}
.joinNowBtnWrapper002-M {
  @include joinNowBtnWrapper-M();
}
.contWrap012-M {
  padding: 0px 10px 10px 10px;
}

.contDiv012-M {
  @include contDiv-M();
}

.continueWithLanding012-M {
  @include continueWithLanding-M();
}

/////////////////////  Hero.js 11 FIGMA 112 4444444444444444444444444444444444444444444

.landingtaglineTop-112 {
  @include taglineTop($font: 32px, $width: 405px);
  padding-left: 11px;
}
.landingtaglineBot-112 {
  @include taglineBot($font: 32px);
}

.headTextLandingContainer-112 {
  @include headTextLandingContainer();
}

.signInFormWrap112 {
  @include signInFormWrap();
}
.signInForms112 {
  @include signInForms();
}

.joinNowBtnWrapper112 {
  @include joinNowBtnWrapper();
  padding-left: 45px;
}
.contWrap112 {
  @include contWrap();
}

.contDiv112 {
  @include contDiv();
}

.continueWithLanding112 {
  @include continueWithLanding();
}

.landingtaglineFirst112-M {
  @include landing--taglineFirst-M($font: 30px, $fontM: 23px);
}

.landingtaglineSecond112-M {
  font-size: 30px;
  right: 20px;
  padding-bottom: 3px;
}
.headTextLandingContainer112-M {
  @include headTextLandingContainer-M($maxWidth: 355px);
}

.headTextLanding112-M {
  @include headTextLanding-M();
}

.variant--eleven_heading-text {
  // margin-right: 100px;
  width: 400px;
}

.signInFormWrap112-M {
  @include signInFormWrap-M($padding: 0px 0px 0px 30px);
}

.signInForm112-M {
  padding: 10px 0 15px 0;
}
.joinNowBtnWrapper112-M {
  margin-bottom: 20px;
}
.contWrap112-M {
  padding: 0px 10px 10px 10px;
}

.contDiv112-M {
  @include contDiv-M();
}

.continueWithLanding112-M {
  @include continueWithLanding-M();
}
.headTextLandingContainer112 {
  @include headTextLandingContainer();
}
