.team-mission-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 52px 72px;
  position: relative;
  overflow-y: auto;

  @media only screen and (max-width: 1140px) {
    padding: 2.5rem 1rem;
  }
}

.team-mission-edit {
  position: absolute;
  right: 50px;
  top: 32px;
  @media (max-width: 1140px) {
    right: 15px;
    top: 10px;
  }
  @media (max-width: 760px) {
    right: 40px;
    top: 10px;
  }
}

.team-mission-edit-icon {
  color: #2eb790;
  cursor: pointer;
  @media only screen and (max-width: 760px) {
    position: absolute;
  }
}

.team-mission-title {
  font-family: Poppins;
  font-weight: bold;
  font-size: 34px;
  line-height: 51px;
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 24px;
}

.team-mission-title--editing {
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 32px;
}

.team-mission-operations {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 3.5rem;
  }

.team-mission-editor-container {
  width: 100%;
  height: 300px;
  margin-bottom: 4rem;
}

.mission-editor-wapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
}

.mission-editor-toolbar {
  background-color: #2eb790 !important;
  border-radius: 4px 4px 0 0 !important;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.mission-editor-editor {
  width: 100%;
  height: 260px;
  border: 1px solid #d8d8d8;
  border-top: none;
  border-radius: 0 0 4px 4px;
  > .DraftEditor-root {
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.editor-link-popup {
  top: 10px !important;
}

.team-mission-description {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 24px;
}

.team-mission-image {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.team-mission-subtitle {
  color: black;
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 24px;
}

.team-mission-subdescription {
  color: black;
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 16px;
  font-weight: 24px;
  letter-spacing: 0.15px;
  margin: 0;
}
