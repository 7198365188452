.container--page_layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: auto 1fr auto / 1fr;
  #mobile {
    display: none;
    @media screen and (max-width: 760px) {
      display: block;
    }
  }
  #desktop {
    display: block;
    @media screen and (max-width: 760px) {
      display: none;
    }
  }
  background-color: rgb(245, 244, 242);
  // display: flex;
  // flex-direction: column;
  // min-height: 100vh;
}

.hero_graphic-wrapper {
  background-color: #eef8f6;
  height: 400px;
  width: 100%;
  position: absolute;
  z-index: -10;
}

.hero_graphic {
  background-repeat: no-repeat;
  height: 100%;
  width: 946px;
  margin: auto;
}

.svg_element {
  transform: translate(40vw, 200px);
}

.container--main {
  width: 100vw;
  display: block;
}

.container--main-home {

  @extend .container--main;
  max-width: 100%;
  background: linear-gradient(180deg, #dffff8 0%, rgba(220, 241, 236, -3) 18%);
  @media screen and (max-width: 1200px) {
    background: linear-gradient(
      180deg,
      #dffff8 0%,
      rgba(220, 241, 236, -3) 12%
    );
  }
  @media screen and (max-width: 1000px) {
    background: linear-gradient(180deg, #dffff8 0%, rgba(220, 241, 236, -3) 9%);
  }
  @media screen and (max-width: 500px) {
    background: linear-gradient(180deg, #dffff8 0%, rgba(220, 241, 236, -3) 6%);
  }
}

.container--dashboard_page_wrapper {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding: var(--page-spacing);
  box-sizing: border-box;
  display: block;
}

#feedbackContact {
  min-height: 150px;
  resize: none;
}

#teamMission {
  resize: none;
}

body {
  background-color: #f5f4f2;
}
