.footer_wrapper {
  width: 100%;
  background-color: #f5f4f2;
  grid-row: 3 / -1;
  clear: both;
  // z-index: -1;
}

.footer_wrapper-white {
  width: 100%;
  background-color: #f5f4f2;
  grid-row: 3 / -1;
  z-index: 1;
}

.container--footer {
  max-width: 1170px;
  min-height: 395px;
  box-sizing: border-box;
  margin: auto;
  display: grid;
  width: 100%;
  grid-template: auto 1fr auto / 0.85fr 1fr 1fr;
  grid-row: 3 / -1;
  row-gap: 24px;
  padding: 36px 28px;
  @media screen and (max-width: 830px) {
    grid-template: auto 1fr 1fr auto auto / 1fr;
    row-gap: 48px;
    padding: 36px 12px;
  }
}

// .container--footer-top {
//   display: grid;
//   grid-column: 1 / -1;
//   grid-row: 1 / 2;
//   border-bottom: 2px solid #f5f4f2;
//   padding-bottom: 24px;

//   @media screen and (max-width: 830px) {
//     grid-column: 1 / -1;
//     grid-row: 1 / 2;
//   }
// }

.container--footer-left {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: left;
  align-items: top;
  row-gap: 10px;
  font-size: 18px;

  @media screen and (max-width: 830px) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    margin-left: 5vw;
  }
}

.container--footer-center {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: grid;
  justify-items: left;
  align-items: top;
  row-gap: 10px;
  margin-left: 5vw;
  font-size: 18px;
  margin-bottom: 4rem;

  @media screen and (max-width: 830px) {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
  }
}

.container--footer-right {
  grid-column: 3 / -1;
  grid-row: 2 / 3;
  display: grid;
  grid-template: auto 1fr / 1fr;
  justify-items: left;
  align-items: top;
  row-gap: 16px;
  margin-left: 5vw;
  font-size: 18px;
  width: fit-content;

  @media screen and (max-width: 830px) {
    grid-column: 1 / -1;
    grid-row: 4 / 5;
  }
}

.header--footer_section_title {
  font-size: 24px;
  line-height: 50px;
  font-family: Poppins;
  color: #001b11;
  font-weight: 800;
  columns: 1 / -1;
  letter-spacing: 0.15px;
}

.link--pagenav {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  columns: 1 / -1;
  color: #001b11;
  opacity: 0.85;

  &:hover {
    color: var(--accent-main);
  }
}

.link--pagenav_support_email {
  font-size: 17.5px !important;
  line-height: 27px;
  font-weight: 400;
  columns: 1 / -1;
  color: #001b11;
  opacity: 0.9;
  text-decoration: underline;

  &:hover {
    color: var(--accent-main);
  }
}

.link--pagenav-border {
  @extend .link--pagenav;
}

// .text--version_footer {
//   grid-column: 2 / 3;
//   opacity: 0.1;
// }
.link--pagenav_support_email {
  font-size: 17.5px !important;
  line-height: 27px;
  font-weight: 400;
  columns: 1 / -1;
  color: #001b11;
  opacity: 0.9;
  text-decoration: underline;

  &:hover {
    color: var(--accent-main);
  }
}

.button--footer {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;

  &:hover {
    color: var(--accent-main);
  }
}

.container--social_media_icons {
  display: flex;
  justify-content: space-around; 
  align-items: center;
  width: 100%; 
}

.link--footer_social {
  margin: 0 10px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.img--footer_social_icon {
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 400px) {
  .container--social_media_icons {
    justify-content: space-between;
  }

  .link--footer_social {
    margin: 0 5px; 
  }
}



.link--footer_social {
  &:hover {
    // border-bottom: 1px solid var(--accent-main);
    transform: scale(1.2);
  }
}

.footer--copyright {
  color: rgba(0, 27, 17, 0.7);
  font-size: 14px;
  margin-bottom: -0.2px;
  width: 100%;
}

.header--footer_copyright_title {
  @extend .header--footer_section_title;
}
.legendYoutube {
  box-sizing: border-box;

  color: #2eb790;

  display: table;

  max-width: 100%;

  padding: 0;

  white-space: normal;
}
