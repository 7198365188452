html,
body,
#__next {
  height: 100%;
  width: 100%;
  // scroll-behavior: smooth;
}

body {
  background-color: var(--bg-white);
  font-family: var(--body-font);
}

.makeGreen {
  color: none;
}

.overflow-hidden {
  overflow: hidden;
}

.item-carousel-img {
  width:100%;
  height:100%;
  overflow:hidden;
  border-radius: 50%;
  border: 3px solid white !important;
}

.link {
  margin: 0;
  padding: 0;
  font-family: var(--header-font);
  color: var(--font-color-light);
  cursor: pointer;
  text-decoration: none;

  &:focus {
    outline: var(--accent-main) auto 5px;
  }
}

// .link.link--external_link {
//   text-decoration: underline;
//   color: var(--accent-main);
//   text-shadow: 0px 0px 0px var(--font-color-dark-p);
// }

.link--footer {
  font-family: var(--body-font);
  color: var(--font-color-light);
  text-decoration: none;
}

// Base button styles
.button {
  font-family: var(--header-font);
  color: var(--font-color-light);
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;

  &:focus {
    outline: var(--accent-main) auto 5px;
  }
}

.select {
  &:focus {
    outline: var(--accent-main) auto 5px;
  }
}

.header {
  color: var(--font-color-dark);
  font-family: var(--header-font);
  text-align: left;
  margin: 0;
  padding: 0;
}

// .header--page_title_h1 {
// }

.header--page_title_h2 {
  font-size: 24pt;
  margin: 16px 0;
  line-height: 1.25;
}

.header--page_title_h2_left {
  @extend .header--page_title_h2;
  text-align: left;
}

.header--page_title_h2_center {
  @extend .header--page_title_h2;
  text-align: center;
}

.header--page_title_h3_left {
  font-size: 20pt;
  text-align: left;
  margin: 16px 0;
  line-height: 1.25;
}
.header--page_title_h4 {
  font-size: 16pt;
  margin: 16px 0;
  line-height: 1.25;
}

.header--page_title_h4_left {
  @extend .header--page_title_h4;
  text-align: left;
}

.header--page_title {
  font-size: 30px;
  padding: 32px 0 32px 0;
}

.header--text_label {
  font-size: 14px;
  font-weight: 500;
}

.header--text_label_application {
  @extend .header--text_label;
  width: 100%;
}

.text--data {
  font-family: var(--body-font);
  font-size: 18px;
  color: var(--font-color-dark);
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 700;
  font-family: var(--header-font);
}

.text--instructions {
  @extend .text--data;
  font-size: 16px;
  margin: 4px 0 0 0;
  max-width: 24em;
  line-height: 1.5;
  opacity: 0.75;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
    max-width: 100%;
  }
}

.text--warning {
  @extend .text--data;
  font-size: 16px;
  margin: 8px 0 0 0;
  line-height: 1.5;
}

.text--mobile_message {
  font-size: 14px;
  opacity: 0.9;
}

.form--section_form {
  padding: 0;
  display: grid;
}

.form--section_form-application {
  @extend .form--section_form;
  row-gap: 24px;
  justify-items: left;
  align-items: center;
  padding: 0px 0;
}

.input {
  margin: 0;
  padding: 0;
  background-color: var(--bg-white);
  border: none;
  &:focus {
    outline: var(--accent-main) auto 5px;
  }
}

.input--profile_form_input {
  @extend .input;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  margin: 4px 0 16px 0;
  padding: 0 0 0 0;
  line-height: 1.5;
  max-width: 35em;
  font-family: var(--body-font);
  color: var(--font-color-dark);
  box-shadow: none;
  border-bottom: 1px solid var(--font-color-gray);
  border-radius: 0;

  @media screen and (max-width: 1140px) {
    font-size: 16px;
  }
}

.hr--dashboard_page_break {
  border: 1px solid var(--bg-lightblue);
  margin: 0;
}

.hr--form_break {
  border: 1px solid var(--bg-lightblue);
  margin: 16px 0;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.container--page_top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.page_top-left {
  grid-column: 1 / 2;
  justify-self: left;
}

// .page_top-right {
//   grid-column: 2 / -1;
//   justify-self: right;
// }

.container--section_data_wrapper {
  grid-column: 2 / -1;
  display: grid;
  column-gap: 24px;
  grid-template: 1fr / 1fr 1.25fr 1fr 150px 150px;
  padding: 32px 0;

  @media screen and (max-width: 1140px) {
    grid-template: auto 1fr / 1fr 90px 90px;
  }
}

.container--section_data_wrapper-one_col {
  grid-column: 1 / -1;
  display: grid;
  grid-template: 1fr / 1fr;
  padding: 32px 0;
  border: none !important;
  box-shadow: none !important;
  position: relative !important;
}

.container--section_data_wrapper-one_col-padding {
  padding: 24px 0 32px 0;
}

.container--section_data_wrapper-one_col-top_padding {
  padding: 32px 0 10px 0;
}

.container--section_instructions {
  grid-column: 1 / 3;
  grid-row: 1 / -1;
  max-width: 35em;

  @media screen and (max-width: 1140px) {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }
}

.container--section_details {
  box-sizing: border-box;
  width: 100%;
  justify-self: left;
  grid-column: 3 / -1;
  display: grid;
  padding: 16px 0;
  border-radius: 5px;

  @media screen and (max-width: 1140px) {
    grid-row: 2 / -1;
    grid-column: 1 / -1;
  }
}

.container--section_details-with_action {
  @extend .container--section_details;
  grid-template-columns: auto 1fr;
}

.header--section_data_label {
  margin: 0;
}

.text--section_data {
  margin: 4px 0 16px 0;
  line-height: 1.5;
  max-width: 35em;
  border-bottom: 1px solid var(--bg-white);
  font-weight: 600;
  font-family: var(--header-font);
  min-height: 27px;

  @media screen and (max-width: 1140px) {
    font-size: 16px;
  }
}

.data-stay_left {
  grid-column: 1 / 2;
  justify-self: start;
}

.container--button_wrapper {
  margin: 0;
  grid-row: 1 / 3;
  grid-column: 3 / -1;
  align-self: center;
  justify-self: end;
}

.container--section_form_buttons {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  align-items: center;
  justify-items: center;
  margin-top: 16px;
}

.section_save_button {
  justify-self: right;
  grid-column: 2 / -1;
  grid-row: 1 / -1;
}

.section_cancel_button {
  justify-self: left;
  grid-column: 1 / 2;
  grid-row: 1 / -1;
}

.span--text-warning {
  font-weight: 600;
  color: var(--font-color-alert);
}

.container--data_table {
  display: grid;
  margin: 0px 0 0px 0;
  border-radius: 5px;
  border: 2px solid var(--bg-lightblue);
}

.container--data_table-padding {
  @extend .container--data_table;
  margin: 16px 0 0px 0;

  @media screen and (max-width: 1140px) {
    width: 100% !important;
  }
}

.header--table_heading_text {
  opacity: 0.75;
  font-weight: 500;
  font-size: 16px;
  grid-row: 1 / -1;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
  }
}

.header--table_heading_text-user_skillset {
  @extend .header--table_heading_text;
  grid-column: 2 / 5;
}

.container--table_header {
  padding: 0 8px;
  display: grid;
  align-items: center;
  column-gap: 24px;
  grid-template: minmax(32px, auto) minmax(32px, auto) / 1.4fr 1fr 1fr 1fr;
  border-radius: 5px 5px 0 0;

  @media screen and (max-width: 1140px) {
    grid-template: 24px 24px / 1fr auto;
    column-gap: 8px;
  }

}

.data-skillset {
  min-width: 0;
  overflow-wrap: break-word;
}

.container--table_row {
  padding: 0 8px;
  @extend .container--table_header;
  border-radius: 0;
  border-top: 2px solid var(--bg-lightblue);
}


.button--user_name_link {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--header-font);
  color: var(--accent-main);
  grid-row: 1 / -1;
  background-color: rgba(24, 24, 24, 0);
  text-align: left;
  width: 100%;
  height: 100%;
  cursor: pointer;
  @media screen and (max-width: 1140px) {
    font-size: 16px;
  }
}

.button--user_name_link-top {
  @extend .button--user_name_link;
  grid-row: 1 / 2;
}

.text--table_data {
  font-size: 16px;
  opacity: 1;
  font-weight: 500;
  font-family: var(--header-font);
  grid-row: 1 / -1;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
  }
}

.data-skillset-rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-row: 1 / -1;
}

.text--table_data_role {
  font-size: 16px;
  opacity: 1;
  font-weight: 500;
  font-family: var(--header-font);
  grid-row: 1 / -1;
  text-transform: capitalize;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
  }
}

// .text--table_data-top {
//   grid-row: 1 / 2;
//   color: var(--accent-main);
// }

.text--table_data-bottom {
  grid-row: 2 / -1;
  grid-column: 1 / 2;
  font-size: 16px;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
  }
}

.table_column_three {
  grid-column: 3 / -1;
}

.table_columns_two_three {
  grid-column: 2 / 4;
}

.table_column_four {
  grid-column: 4 / 5;

  @media screen and (max-width: 1140px) {
    grid-column: 3 / 4;
  }
}

// .table_column_five {
//   grid-column: 5 / -1;
// }

.table_columns_four_five {
  grid-column: 4 / -1;

  @media screen and (max-width: 1140px) {
    grid-column: 2 / 3;
  }
}

.table_button {
  grid-row: 1 / -1;
}

.button--results_list-col_end {
  grid-column: 6 / -1;
}

// .text--logic_error {
//   color: var(--font-color-dark);
//   font-weight: 700;
//   font-family: var(--header-font);
//   padding: 8px;
//   border: 2px solid var(--font-color-alert);
//   border-radius: 5px;
// }

.text--message-small {
  color: var(--font-color-dark);
  font-weight: 700;
  font-family: var(--header-font);
  padding: 0px;
}

// .span--logic_error_symbol {
//   color: var(--font-color-alert);
//   font-size: 16px;
//   padding: 0px 4px;
//   margin-right: 8px;
// }

.span--verified-false {
  color: var(--font-color-alert);
}

.container--alert_message_wrapper {
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr auto;
  align-items: center;
  padding: 16px 16px;
  z-index: 9999;
  opacity: 1;
}

// Toastify style override
.Toastify__toast.Toastify__toast--info {
  background-color: #fff;
  opacity: 0.95;
  border-radius: 10px;
  border: 1px solid var(--bg-white-transparent);
  z-index: 9999;
}

.text--alert_message {
  font-family: var(--notfound-font) !important;
  color: var(--font-color-dark);
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 16px;
  grid-row: 1 / -1;
  grid-column: 1 / 2;
}

.particlesBackground {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}

#particles-js {
  position: fixed;
  width: 100%;
  height: 100% !important;
  background-size: cover !important;
}

.container--add_member_option {
  margin-top: 1rem;
}
