@import "node_modules/react-datepicker/src/stylesheets/datepicker.scss";

.public-calendar-tab {
  width: 100%;
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1.5fr;

  @media only screen and (max-width: 760px) {
    padding: 1rem;
    max-height: 750px;
    overflow: auto;
    display: block;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.calendar-tab {
  width: 100%;
  height: 100%;
  padding: 6px;
  margin-bottom: 30px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 470px 1fr;

  // @media only screen and (max-width: 760px) {
  @media only screen and (max-width: 1140px) {
    padding: 1rem;
    // max-height: 750px;
    // max-height: 980px;
    // overflow: auto;
    // max height overflows on other components for small screens
    display: block;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.public-event-view-container {
  width: 250px;
  height: 100%;
  border-right: 1px solid #d8d8d8;
  scroll-behavior: smooth;
}

.event-view-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-right: 1px solid #d8d8d8;
  scroll-behavior: smooth;
}

.event-date {
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-monthday {
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 64px;
    line-height: 1;
    letter-spacing: 0.15px;
    text-align: center;
    margin: 0;
    margin-bottom: 6px;
  }

  &-weekday {
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 18x;
    line-height: 27px;
    letter-spacing: 0.15px;
    text-align: center;
    margin: 0;
  }
}
.event-list {
  width: 100%;
  height: calc(100% - 170px);
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  .event-list-items {
    max-height: 150px;
    margin-right: 30px;
    overflow: hidden;

    &:hover {
      overflow-y: scroll;
    }

    .event {
      display: grid;
      grid-template-columns: 0fr 1fr;
      border: 1px solid #d8d8d8;
      border-right: none;
      border-top: none;
      border-left: none;
      margin-top: 0.3rem;
    }

    .delete-event-button {
      cursor: pointer;
      margin-top: 0.4rem;
    }

    .item {
      cursor: pointer;
      vertical-align: middle;

      &:hover {
        background-color: rgba(196, 196, 196, 0.15);
        transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
      &:disabled {
        pointer-events: none;
      }
    }
  }

  .public-event-list-item-header {
    width: 250px;
  }

  .event-list-item-header {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    box-sizing: border-box;

    .event-list-body {
      width: 200px;
    }
  }

  .timestart {
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #001b11;
    letter-spacing: 0.15px;
  }
  .dash {
    margin: 0 0.3rem;
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #001b11;
    letter-spacing: 0.15px;
    width: 100px;
  }
  .timeend {
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #001b11;
    letter-spacing: 0.15px;
  }

  .public-title {
    margin: 0.5rem 0;
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #001b11;
    letter-spacing: 0.15px;
  }

  .title {
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #001b11;
    letter-spacing: 0.15px;
  }

  .public-event-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    height: 20px;
  }

  .event-title {
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 140px;
    display: inline-block;
    vertical-align: middle;
  }

  .event-list-item-content {
    margin: 30px 30px 0 0;
    border: 1px solid #d8d8d8;
    border-radius: 4px 4px 0px 0px;

    .event-list-description {
      max-height: 200px;
      overflow: hidden;

      &:hover {
        overflow-y: scroll;
      }
    }

    .header {
      width: 100%;
      height: 36px;
      border-radius: 4px 4px 0 0;
      background-color: #2eb790;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .announcements {
        font-family: "Open Sans";
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
        color: white;
        letter-spacing: 0.15px;
      }
      .plus {
        position: absolute;
        font-size: 24px;
        color: white;
        top: 0;
        right: 20px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
      }
    }
    .content {
      border-radius: 0 0 4px 4px;
      font-family: "Open Sans";
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.15px;
      display: grid;
      grid-template-columns: 2.5fr 1fr;

      .event--item-description {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .event--item-description-time {
        text-align: right;
      }

      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      svg {
        margin-left: 0.8rem;
        vertical-align: middle;
      }

      a {
        color: #2252b7;
      }
    }
  }
}

.line {
  border-bottom: 1px solid #d8d8d8;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
}

.list-events-description:last-child div {
  border-bottom: none;
}

.calendar-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.web-event-calendar-container, .event-calendar-container {
  width: 373px;
  position: relative;
  // .makeStyles-dateStyle-164 {
  //   margin-bottom: 2rem !important;
  // }
  .event-calendar {
    border: none;
    width: 100%;

.react-calendar__viewContainer {
      .react-calendar__tile {
        width: 40px;
        height: 40px;
        margin: 5px;
        border-radius: 50%;
        padding: 0;
        max-width: 40px !important;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: black;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition:300ms;
        &:hover {
          background-color: #cfcfcf;
          transition:300ms;
        }
      }
      .react-calendar__month-view__days__day--neighboringMonth {
        color: #c4c4c4;
      }

      .react-calendar__tile--now {
        background-color: #2eb790 !important;
        color: #fff !important;
      }

      .react-calendar__tile--active {
        background: #000000 !important;
        color: #fff;
      }

      .highlight {
        background-color: #dcf1ec;
      }
    }

    .react-calendar__month-view__days {
      margin-bottom: 2rem;
    }
    .react-calendar__month-view__weekdays {
      margin-bottom: 1rem;
      text-align: center;
      .react-calendar__month-view__weekdays__weekday {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: black;
        > abbr {
          text-decoration: none;
        }
      }
    }
    .react-calendar__navigation {
      width: 320px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      margin-bottom: 1rem;
      .react-calendar__navigation__label {
        border: none;
        width: 192px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: black;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
      .react-calendar__navigation__prev-button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        color: #d8d8d8;
        background-color: transparent;
        cursor:pointer;
        &:hover {
          color: black;
          background-color: transparent;
        }
      }
      .react-calendar__navigation__next-button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        color: #d8d8d8;
        background-color: transparent;
        cursor:pointer;
        &:hover {
          color: black;
          background-color: transparent;
        }
      }
      .react-calendar__navigation__prev2-button {
        display: none;
      }
      .react-calendar__navigation__next2-button {
        display: none;
      }
    }
  }
}

// .web-event-calendar-container {
//   .react-calendar__tile {
//     margin: 12px;
//   }
// }
.web-event-calendar-container .react-calendar__tile {
  margin: 12px;
}
.web-event-calendar-container{
  .event-calendar {
.react-calendar__viewContainer {
      .react-calendar__tile {
        margin: 12px;
      }
    }
  }
}

.add-event {
  text-align: center;
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
}

// Calendar Card

.calendar-card-container {
  @media screen and (max-width: 1140px) {
    max-width: 300px;
    text-align: center;
    margin: auto;
  }
}

.calendar-card-event-date {
  margin: 2rem 0;

  @media screen and (max-width: 1140px) {
    margin: 1rem 0;

    label {
      position: relative;
    }

    span {
      position: absolute;
      top: -18px;
    }
  }

  input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.15px;
    color: #000000;
    border: none;
    width: 220px;

    @media screen and (max-width: 768px) {
      width: 300px;
      font-size: 30px;
    }
  }

  span {
    position: absolute;
    margin-top: 0.6rem;
    left: 50%;
    cursor: pointer;

    @media screen and (max-width: 1140px) {
      position: absolute;
      bottom: 31.5%;
      right: 0;
    }
  }
}

.calendar-card-date-picker {
  display: inline-block;
  margin-left: 20px;

  .calendar-pick-date {
    position: absolute;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.calendar-card-select-time {
  @media screen and (max-width: 1140px) {
    display: flex;
    justify-content: space-around;
  }

  hr {
    border: none;
    background: rgba(0, 27, 17, 0.7);
    width: 20px;
    height: 1px;
    display: inline-block;
    margin: 0px 15px;
    vertical-align: middle;

    @media screen and (max-width: 1140px) {
      margin-top: 1.2rem;
    }
  }

  input[type="time"] {
    width: 161px;
    height: 40px;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;

    &::-webkit-calendar-picker-indicator {
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%2300A682" height="24" viewBox="0 0 13 8" width="20" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11.09 0.589844L6.5 5.16984L1.91 0.589844L0.5 1.99984L6.5 7.99984L12.5 1.99984L11.09 0.589844Z"/%3E%3Cpath fill="none"/%3E%3C/svg%3E');
      cursor: pointer;
    }
  }
}

.calendar-card-event-title {
  margin: 20px 0;
  width: 100%;
  height: 40px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  &::placeholder {
    color: #b3b3b3;
  }
}

.event-edit-form {
  overflow: auto;

  textarea {
    outline: none;
  }

  .event-description-edit {
    width: 95%;
    height: 150px;
    border: none;
    padding: 10px 0px 10px 10px;
    resize: none;
  }

  button {
    border: none;
    background-color: transparent;
    float: right;
    cursor: pointer;
  }
}

.div-button {
  button {
    margin: 40px 0 10px 30px;
  }
}

.div-button-edit {
  display: flex;
  margin-top: 70px;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.div-button-create {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  flex-direction: row-reverse;
}


.cancel-button {
  color: #e4475b;
  letter-spacing: 0.15px;
  font-weight: 600;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #e4475b;
  border-radius: 4px;
  width: 117px;
  height: 40px;
  float: right;
  cursor: pointer;
}

.save-button {
  letter-spacing: 0.15px;
  font-weight: 600;
  font-size: 16px;
  background: #2eb790;
  border: none;
  border-radius: 4px;
  color: white;
  width: 117px;
  height: 40px;
  float: right;
  text-transform: capitalize;
  cursor:pointer;
  &:hover {
    background: #2eb790;
    box-shadow: none;
  }
}

.react-datepicker {
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
  border-radius: 12px !important;
  color: #fff;

  @media screen and (max-width: 768px) {
    margin-left: 2rem;
  }
}

.react-datepicker__input-container{
  margin-left: 8px
}

.public-list {
  margin-bottom: 0.6rem !important;
}

.react-datepicker__month-container {
  font-size: 14px;
  padding: 0.5rem;

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }

  .react-datepicker__day--today {
    background-color: #2eb790;
    color: #fff;
    border-radius: 100px;
  }

  .react-datepicker__day--selected {
    background-color: #000;
    border-radius: 100px;
    &:hover {
      background-color: #d8d8d8;
      border-radius: 100px;
    }
  }

  .react-datepicker__month {
    padding: 1rem 0;
  }

  .react-datepicker__month-text {
    display: inline-block;
    width: 5rem;
    margin: 0.5rem;
    font-size: 1rem;
    padding: 0.2rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1.8rem;
    height: 1.8rem;
    font-family: "Open Sans";
  }
}
