.invitees-container {
  position: relative;
  margin: 0 auto;
  padding: 40px 70px;

  @media only screen and (max-width: 1140px) {
    padding: 2.5rem;
    max-height: 400px;

    &::-webkit-scrollbar {
      display: none;
    }

    .mobile-grid {
      // margin: 0 -1rem 0 !important;
      display: flex;
      justify-content: center;
    }
    .invitees-button-search {
      background-color: #2eb790;
      border: none;
      border-radius: 4px 0px 0px 4px;
      outline: none;
      cursor: pointer;
    }
  }

  button {
    cursor: pointer;
  }

  .invite-card-body {
    width: 100%;
    padding-top: 20px;
    box-sizing: border-box;
    position: relative;
    border-radius: 0 0 12px 12px;
    text-align: center;
  }

  .declined-status {
    color: rgba(0, 27, 17, 0.7);
    letter-spacing: 0.15px;
    text-align: center;
    font-style: italic;
  }
}

.invitees-wrapper {
  overflow: auto;
  margin-bottom: 64px;
  margin-top: 34px;
}

.invitees-search {
  width: 411px;
  display: grid;
  grid-template: 1fr auto / auto auto 1fr;
  align-items: center;
  justify-items: left;
  margin: 40px auto 20px;

  @media only screen and (max-width: 1140px) {
    width: 265px;
    margin: 0 auto 1rem;
  }

  .invitees--search-icon {
    box-sizing: border-box;
    height: 42px;
    border-radius: 0px 5px 5px 0px;
    -webkit-border-radius: 0px 5px 5px 0px;
    padding: 4px 8px;
    font-size: 16px;

    @media only screen and (max-width: 1140px) {
      width: 30px;
    }
  }

  .invitees--search_input {
    width: 345px;
    border: none;
    height: 32px;
    border: 1px solid #d8d8d8;
    border-radius: 5px 0px 0px 5px;
    -webkit-border-radius: 5px 0px 0px 5px;
    padding: 4px 8px;
    font-size: 16px;

    &::placeholder {
      color: #a9a9a9;
    }

    @media only screen and (max-width: 1140px) {
      width: 185px;
    }
  }
}

.invitees-sortes {
  margin-top: 20px;
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 400;
  float: right;

  @media only screen and (max-width: 1140px) {
    display: none;
  }
}

.invitees-invite-button {
  border: none;
  background-color: #2eb790;
  color: white;
  border-radius: 100px;
  width: 128px;
  height: 31px;
}

.invitees-email-invitation {
  margin: 5rem 0 2rem;
  letter-spacing: 0.15px;
  text-align: center;

  @media only screen and (max-width: 1140px) {
    margin: 2rem 0;
  }

  .invitees-load-more {
    margin-bottom: 3.5rem;

    button {
      letter-spacing: 0.15px;
      text-decoration-line: underline;
      border: none;
      background-color: transparent;
      color: #2eb790;
      font-weight: 600;
      font-size: 17px;
    }
  }

  span {
    color: #808d88;
  }

  .invitees-email__button {
    border: 1px solid #6380f2;
    background-color: transparent;
    padding: 8px 10px;
    color: #6380f2;
    border-radius: 30px;
    margin-left: 0.5rem;

    @media only screen and (max-width: 1140px) {
      margin: 2rem 0 0;
    }
  }
}
