.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin-top: 0 !important;
  z-index: 90000 !important;

  // overflow: auto!important;
}

// Page wrapper for modal
.container--modal_wrapper {
  z-index: 100000 !important;
  position: fixed;
  place-content: center; // in wider media use center for nicer effect, but narrower should use flex start
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  // z-index: 10001;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  background-color: #00000088;
  // background-color: var(--bg-white-transparent);
  // padding-right: 17px;
}
// Page wrapper for modal
.container--modal_wrapper.signUpForm_delta {
  position: fixed;
  place-content: center; // in wider media use center for nicer effect, but narrower should use flex start
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1200 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00000088;
  // @media only screen and (max-width: 560px) {
  //   // padding-bottom: 20rem;
  // }
}
.container--modal-dark.newPublicPerson {
  width: 500px;
}
.container--modal_wrapper-CareerCard {
  position: fixed;
  place-content: center; // in wider media use center for nicer effect, but narrower should use flex start
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00000088;
  @media screen and (max-height: 700px) {
    place-content: flex-start;
  }
}
.careerCard {
  max-width: 600px;
  @media screen and (max-width: 400px) {
    margin-bottom: 0;
  }
}
.boldCareerCard {
  font-weight: bold;
  font-size: 13px;
  display: inline;
}
.careerCardText {
  padding-left: 80px;
}

.container--page_modal_wrapper {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.w-100 {
  width: 100%;
}

// Modal container
.container--modal-dark {
  display: grid;
  border-radius: 5px;
  background-color: var(--bg-white);
  box-shadow: var(--shadow-high);
  box-sizing: border-box;
  padding-bottom: 32px;
  position: absolute;
  color: black;
  max-width: 1100px;
  width: auto;

  // @media screen and (max-height: 700px) {
  //   place-content: flex-start;
  //   max-width: 90%;
  //   margin-top: 10px;
  // }
}

.container--modal-dark.signUpForm_alpha {
  display: grid;
  border-radius: 5px;
  background-color: var(--bg-white);
  box-shadow: var(--shadow-high);
  box-sizing: border-box;
  padding-bottom: 32px;
  position: absolute;
  color: black;
  top: 5rem;
  object-fit: contain;
  max-width: 90vw;
  // width: 500px;
  z-index: 100000 !important;

  @media only screen and (max-width: 760px) {
    width: 20rem;
    overflow-y: auto;
    top: 1rem;
  }

  @media screen and (max-height: 650px) {
    place-content: flex-start;
    margin-top: 4rem;
    overflow-y: scroll;
    bottom: 5rem;
  }
}
.contact--us_feedback {
  width: 500px;

  @media only screen and (max-width: 760px) {
    width: 350px;
  }
}

.container--modal-light {
  @extend .container--modal-dark;
  background-color: var(--bg-white);
  border: 2px solid var(--bg-darkestblue);
  padding-top: 1rem;
}

.container--preview_modal {
  @extend .container--modal-dark;
  background-color: var(--bg-white);
}

.wrapperApplicatiionLet {
  @media screen and (max-width: 760px) {
    width: 350px;
  }
}

// This is the wrapper
.container--modal_auth_forms_wrapper {
  display: block;
}

.container--inner_modal_bottom-user_preview {
  display: grid;
  padding: 32px 0 0 0;
}

// Top heading for the modal
.header--modal_heading {
  font-size: 24px;
  font-weight: 500;
  margin: 0px 0 24px 0;
  grid-row: 2 / 3;
  grid-column: 1 / -1;

  @media screen and (max-height: 700px) {
    font-size: 18px;
  }
}



.header--modal_heading-with_sub_heading {
  @extend .header--modal_heading;
  margin: 0px 0 8px 0;
}


.header--modal_sub_heading {
  font-size: 14px;
  font-weight: 100;
  margin: 0px 0 24px 0;
  grid-column: 1 / -1;
  line-height: 1.5;
}
.header--modal_heading-dark {
  @extend .header--modal_heading;
  color: #2eb790;
  text-align: left;
}

.app-letter_error {
  margin-bottom: 2rem !important;
}

// Top heading for the confirm modal
.header--modal_confirm-top {
  grid-row: 1 / 2;
  grid-column: 1 / -1;
}

// Team upload avatar input

.team--modal_upload_avatar {
  position: absolute;
  visibility: hidden;
  @media screen and (max-width: 768px) {
    width: 150px;
  }
}

// Wrapper for the inputs, labels, buttons
.form--modal_form {
  display: grid;
  row-gap: 16px;
  justify-items: left;
  align-items: center;
  padding: 0px 0;
}

.container--auth_buttons {
  @extend .form--modal_form;
}

//* Labels ----------------
.label--form_label {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  width: 100%;
  margin: 0;
  padding: 3px;
}
.label--form_label_forget_pass {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  width: 100%;
  margin: 0;
}

// This label will be hidden and is for honey pots.
.label--form_label-honey {
  display: none !important;
}

//* Inputs ---------------
.input--form_input {
  box-sizing: border-box;
  color: black;
  display: block;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  border-color: rgba(0, 27, 17, 0.3);
  border-style: solid;
  border-width: 1px;
  font-size: 18px;
  margin-top: 4px;
  opacity: 1;

  @media screen and (max-height: 700px) {
    font-size: 14px;
  }
}

// This label will be hidden and is for honey pots.
.input--form_input-honey {
  display: none !important;
}

.new_team-modal {
  border: 1px solid #2eb790;
  font-family: "Open Sans" !important;
  // font-weight: 400;
  // letter-spacing: 0.05px !important;
  font-size: 18px;
  color: #040f0b;
}

.input--form_text_area {
  width: 300px;
  height: 200px !important;
  @media (max-width: 400px) {
    width: 250px;
    height: 300px;
  }
  @media (max-width: 320px) {
    width: 200px;
    height: 300px;
  }
}

.contact--us {
  font-size: x-large;
}

// Shorter inputs for skills
.input--form_input-short {
  box-sizing: border-box;
  color: var(--font-color-dark);
  display: block;
  padding: 8px;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 12px;
  background-color: var(--bg-white);
  grid-column: 1 / 2;
}

// For current email
.input--form_input-disabled {
  @extend .input--form_input;
  color: var(--font-color-light-p);
  background-color: var(--bg-blue);
  padding: 8px 0;
  border: none;
  box-shadow: none;
}

.select--form_select {
  display: block;
  margin: 8px 0;
}

.option--form_select_option {
  padding: 0.5rem 3rem !important;
}

// Text for closed team
.text--status_display {
  font-weight: 300;
}

// Button group in confirm modal
.container--modal_buttons {
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

// In signup and login forms
.signup-Form-Privacy {
  margin-left: -38px;
}
// Styles for element below modal
.container--form_select {
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  display: grid;
  justify-items: center;
}

// Next to select form button
.text--form_select {
  color: var(--font-color-dark);
  font-family: var(--header-font);
  font-weight: 400;
}

.list--modal_error_list {
  margin: 0 0 0 0;
}

// Errors for modal
.item--modal_error_item {
  width: 100%;
  box-sizing: border-box;
  color: var(--font-color-error);
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0;
}

// Auth buttons general styles
.link--auth_button {
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  transition: transform 0.25s ease-in, box-shadow 0.25s ease-in;
  width: 100%;
  height: 38px;
  display: grid;
  grid-template: 1fr / auto 1fr;
  align-items: center;
  justify-items: center;
  padding: 0px;
  box-sizing: border-box;
}

// Facebook auth button
.link--facebook_auth {
  background-color: #3578e5;
  border: 1px solid #4267b2;
}

.link--google_auth {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: var(--font-color-dark);
}

.link--twitter_auth {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: var(--font-color-dark);
}

.link--linkedin_auth {
  background-color: #0077b5;
  border: 1px solid #4267b2;
}

.img--button_brand_logo {
  width: 34px;
}

.img--button_brand_logo-twitter {
  width: 20px;
  padding-left: 4px;
  padding-right: -4px;
}

.appliLetterTitle {
  z-index: 10;
  position: relative;
  bottom: 33px;
  @media (max-width: 325px) {
    font-size: 10px !important;
    bottom: 10px;
  }
}

.container--teammate-review-modal {
  display: grid;
  background-color: var(--bg-white);
  box-shadow: var(--shadow-high);
  box-sizing: border-box;
  padding-bottom: 32px;
  position: absolute;
  color: black;
  width: 600px;
  border-radius: 12px;

  @media screen and (max-width: 700px) {
    width: 90vw;
    margin: 20px 0;
  }

  .container--inner_modal_teammate_review {
    width: 100%;
    padding: 0 48px;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 700px) {
      padding: 0 16px;
    }

    h2 {
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 24px;
    }

    .teammate_submit_button_container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
    }

    .teammate_submit_button {
      background: #2eb790;
      border-radius: 24px;
      width: 169px;
      height: 37px;
    }
  }

  .teammate_info {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 60px;

    @media screen and (max-width: 700px) {
      padding-bottom: 20px;
    }

    .image--teammate_review_avatar {
      height: 26px;
      border-radius: 50%;
      background: url("/avatar_header_icon_backup.png") no-repeat;
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: rgba(0, 27, 17, 0.5);
    }
  }

  .teammate_form {
    display: flex;
    gap: 42px;
    width: 100%;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }

    .rating_box {
      width: 220px;
      height: 110px;
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba($color: #000000, $alpha: 0.1);

      .header--skillset_rating {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 3px;
        position: relative;
        width: 100%;
        opacity: 0.8;
        background-color: #2eb790;
      }

      .header--teamplayer_rating {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 3px;
        position: relative;
        width: 100%;
        opacity: 0.8;
        background-color: #e67c89;
      }

      h3 {
        padding-top: 20px;
        margin: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }

      fieldset {
        text-align: center;
      }
    }
  }

  .textarea_teammate_review {
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    label {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #001b11;
      span {
        opacity: 0.5;
      }
    }

    textarea {
      width: 100%;
      height: 160px;
      border: 1px solid rgba(0, 27, 17, 0.2);
      border-radius: 4px;
      margin-top: 10px;
    }
  }
}

.link--pagenav,
.link--pagenav-border {
  cursor: pointer !important;
}

.containerYouTube {
  @media (max-width: 500px) {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin: 10px;
    border-radius: 15px;
  }
}

.responsive-iframe {
  @media (max-width: 500px) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.test1 {
  border-radius: "25px";
  color: red;
}

.careerCardContentJob {
  width: "350px";
}

.cardWrapperJob {
  border-radius: 15px;
  border: 2px solid transparent;
  transition-property: border-color;
  transition: 0.5s;
}

.cardWrapperJob:hover {
  border-color: #2eb790;
}

.container--modal_cancel_application_declined {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
}

// Page wrapper for modal
.container--modal_wrapper {
  position: fixed;
  z-index: 100000;
  place-content: center; // in wider media use center for nicer effect, but narrower should use flex start
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 90000;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  background-color: #00000088;
  // background-color: var(--bg-white-transparent);
  // padding-right: 17px;
  @media screen and (max-height: 700px) {
    // place-content: flex-start;
  }
}

.careerCard {
  max-width: 600px;

  // position: relative;
  // top:70px ;
}

.careerCardText {
  padding-left: 80px;
}

.container--page_modal_wrapper {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.w-100 {
  width: 100%;
}

.container--modal-dark-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--bg-white);
  box-shadow: var(--shadow-high);
  box-sizing: border-box;
  padding-bottom: 24px;
  // margin-top: 8rem;
  position: absolute;
  color: black;
  max-width: 50%;

  @media screen and (max-width: 400px) {
    max-width: 90%;
  }

  @media screen and (max-height: 700px) {
    place-content: flex-start;
    max-width: 90%;
    margin-top: 30px;
  }
}

.container--modal-light {
  @extend .container--modal-dark;
  background-color: var(--bg-white);
  border: 2px solid var(--bg-darkestblue);
}

.container--preview_modal {
  @media screen and (max-width: 760px) {
    width: 27rem;
  }
  @extend .container--modal-dark;
  background-color: var(--bg-white);
}

.container--inner_modal {
  max-width: 500px;
  padding: 0 1.5rem;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 500px) {
    padding: 0 16px;
  }
}

.container--inner_modal_application {
  max-width: 500px;
  padding: 0 0.8rem;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 500px) {
    padding: 0 16px;
  }
}

.container--inner_modal_reset {
  max-width: 500px;
  padding: 0 1.5rem;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 500px) {
    padding: 0 16px;
  }
}

.container--inner_modal-denie {
  padding: 0 1.5rem;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 500px) {
    padding: 0 16px;
  }
}

.user-preview-modal {
  width: 100%;
  padding-bottom: 1rem;
}

// This is the wrapper
.container--modal_auth_forms_wrapper {
  display: block;
}

.container--inner_modal_bottom-user_preview {
  display: grid;
  padding: 32px 0 0 0;
}

// Top heading for the modal
.header--modal_heading {
  font-size: 24px;
  font-weight: 500;
  margin: 0px 0 24px 0;
  grid-row: 2 / 3;
  grid-column: 1 / -1;

  @media screen and (max-height: 700px) {
    font-size: 18px;
  }
}

.header--modal_heading-with_sub_heading {
  @extend .header--modal_heading;
  margin: 0px 0 8px 0;
}

.header--modal_sub_heading {
  font-size: 14px;
  font-weight: 100;
  margin: 0px 0 24px 0;
  grid-column: 1 / -1;
  line-height: 1.5;
}

.header--modal_sub_heading-reset {
  font-size: 14px;
  font-weight: 100;
  margin: 0px 0 24px 0;
  grid-column: 1 / -1;
  line-height: 1.5;
}

.header--modal_heading-dark {
  @extend .header--modal_heading;
  text-align: left;
}

// Top heading for the confirm modal
.header--modal_confirm-top {
  grid-row: 1 / 2;
  grid-column: 1 / -1;
}

// Team upload avatar input

.team--modal_upload_avatar {
  position: absolute;
  visibility: hidden;
}

// Wrapper for the inputs, labels, buttons
.form--modal_form {
  display: grid;
  row-gap: 16px;
  justify-items: left;
  align-items: center;
  padding: 0px 0;
}

.container--auth_buttons {
  @extend .form--modal_form;
}

//* Labels ----------------
.label--form_label {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  width: 100%;
  margin: 0;
  padding: 3px;
}

// This label will be hidden and is for honey pots.
.label--form_label-honey {
  display: none !important;
}
.application-message {
  color: rgba(0, 27, 17, 0.7);
  width: 390px;
  text-align: justify;
  text-justify: inter-word;

  @media screen and (max-width: 760px) {
    width: 300px;
  }
}
//* Inputs ---------------
.input--form_input {
  margin-top: 1rem !important;
  box-sizing: border-box;
  color: var(--font-color-dark);
  display: block;
  width: 100% !important;
  padding: 0.5rem;
  border-radius: 5px;
  border-color: rgba(0, 27, 17, 0.3);
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  margin-top: 4px;
  opacity: 1;
  resize: none;

  ::placeholder {
    color: #a9a9a9;
    font-weight: 700;
  }

  @media screen and (max-height: 700px) {
    font-size: 14px;
  }
}

// This label will be hidden and is for honey pots.
.input--form_input-honey {
  display: none !important;
}

.new_team-modal {
  border: 1px solid #2eb790;
  font-family: monospace;
  font-size: 18px;
  color: #040f0b;
}

.text-area-report-modal {
  margin-top: 0 !important;
  border: 1px solid rgb(216, 216, 216);
  font-family: "Open Sans" !important;
  // font-weight: 400;
  // letter-spacing: 0.05px !important;
  font-size: 16px;
  color: #040f0b;
}

.text-area-report-modal:focus {
  outline: none !important;
  // border: 1px solid rgb(216,216,216) !important;
}

.input--form_text_area {
  width: 300px;
  height: 400px;
  @media (max-width: 400px) {
    width: 250px;
    height: 300px;
  }
  @media (max-width: 320px) {
    width: 200px;
    height: 300px;
  }
}

// Shorter inputs for skills
.input--form_input-short {
  box-sizing: border-box;
  color: var(--font-color-dark);
  display: block;
  padding: 8px;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 12px;
  background-color: var(--bg-white);
  grid-column: 1 / 2;
}

// For current email
.input--form_input-disabled {
  @extend .input--form_input;
  color: var(--font-color-light-p);
  background-color: var(--bg-blue);
  padding: 8px 0;
  border: none;
  box-shadow: none;
}

.select--form_select {
  display: block;
  margin: 8px 0;
}

.option--form_select_option {
  color: var(--font-color-dark);
  background-color: var(--bg-white);
}

// Text for closed team
.text--status_display {
  font-weight: 300;
}

// Button group in confirm modal
.container--modal_buttons {
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

// In signup and login forms

// Styles for element below modal
.container--form_select {
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  display: grid;
  justify-items: center;
}

// Next to select form button
.text--form_select {
  color: var(--font-color-dark);
  font-family: var(--header-font);
  font-weight: 500;
}

.list--modal_error_list {
  margin: 0 0 0 0;
}

// Errors for modal
.item--modal_error_item {
  width: 100%;
  box-sizing: border-box;
  color: var(--font-color-error);
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0;
}

// Auth buttons general styles
.link--auth_button {
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  transition: transform 0.25s ease-in, box-shadow 0.25s ease-in;
  width: 100%;
  height: 38px;
  display: grid;
  grid-template: 1fr / auto 1fr;
  align-items: center;
  justify-items: center;
  padding: 0px;
  box-sizing: border-box;
}

// Facebook auth button
.link--facebook_auth {
  background-color: #3578e5;
  border: 1px solid #4267b2;
}

.link--google_auth {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: var(--font-color-dark);
}

.link--twitter_auth {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: var(--font-color-dark);
}

.link--linkedin_auth {
  background-color: #0077b5;
  border: 1px solid #4267b2;
}

.img--button_brand_logo {
  width: 34px;
}

.img--button_brand_logo-twitter {
  width: 20px;
  padding-left: 4px;
  padding-right: -4px;
}

.appliLetterTitle {
  z-index: 10;
  position: relative;
  bottom: 33px;
  @media (max-width: 325px) {
    font-size: 10px !important;
    bottom: 10px;
  }
}

.container--teammate-review-modal {
  display: grid;
  background-color: var(--bg-white);
  box-shadow: var(--shadow-high);
  box-sizing: border-box;
  padding-bottom: 32px;
  position: absolute;
  color: black;
  width: 600px;
  border-radius: 12px;

  @media screen and (max-width: 700px) {
    width: 90vw;
    margin: 20px 0;
  }

  .container--inner_modal_teammate_review {
    width: 100%;
    padding: 0 48px;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 700px) {
      padding: 0 16px;
    }

    h2 {
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 24px;
    }

    .teammate_submit_button_container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
    }

    .teammate_submit_button {
      background: #2eb790;
      border-radius: 24px;
      width: 169px;
      height: 37px;
    }
  }

  .teammate_info {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 60px;

    @media screen and (max-width: 700px) {
      padding-bottom: 20px;
    }

    .image--teammate_review_avatar {
      height: 26px;
      border-radius: 50%;
      background: url("/avatar_header_icon_backup.png") no-repeat;
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: rgba(0, 27, 17, 0.5);
    }
  }

  .teammate_form {
    display: flex;
    gap: 42px;
    width: 100%;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }

    .rating_box {
      width: 220px;
      height: 110px;
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba($color: #000000, $alpha: 0.1);

      .header--skillset_rating {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 3px;
        position: relative;
        width: 100%;
        opacity: 0.8;
        background-color: #2eb790;
      }

      .header--teamplayer_rating {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 3px;
        position: relative;
        width: 100%;
        opacity: 0.8;
        background-color: #e67c89;
      }

      h3 {
        padding-top: 20px;
        margin: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }

      fieldset {
        text-align: center;
      }
    }
  }

  .textarea_teammate_review {
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    label {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #001b11;
      span {
        opacity: 0.5;
      }
    }

    textarea {
      width: 100%;
      height: 160px;
      border: 1px solid rgba(0, 27, 17, 0.2);
      border-radius: 4px;
      margin-top: 10px;
    }
  }
}

.link--pagenav,
.link--pagenav-border {
  cursor: pointer !important;
}

.containerYouTube {
  @media (max-width: 500px) {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin: 10px;
    border-radius: 15px;
  }
}
.containerYouTubeIndex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: fit-content;
  height: min-content;
  border-radius: 15px;
  @media (max-width: 700px) {
    width: 30rem;
    border-radius: 15px;
  }
  @media (max-width: 500px) {
    position: relative;
    overflow: hidden;
    padding-top: 46.25%;
    width: 24rem;
    border-radius: 15px;
  }
  @media (max-width: 380px) {
    width: 18rem;
  }
  @media (max-width: 290px) {
    width: 15rem;
  }
}

.responsive-iframeYouTubeIndex {
  border-radius: 15px;
  outline: none;
  border: none;

  @media (max-width: 500px) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.test1 {
  border-radius: "25px";
  color: red;
}

.careerCardContentJob {
  width: "350px";
}

.cardWrapperJob {
  border-radius: 15px;
  border: 2px solid transparent;
  transition-property: border-color;
  transition: 0.5s;
}

.cardWrapperJob:hover {
  border-color: #2eb790;
}

.container--modal_cancel_application_declined {
  width: 100%;
  border: 2px solid red;
  height: 50px;

  button {
    float: right;
  }
}

// Upgrade Team Modal

.upgrade-team-container--modal-dark,
.report-container--modal-dark {
  display: grid;
  border-radius: 5px;
  padding: 30px 40px 20px 40px;
  background-color: #ecfcf8;
  box-shadow: var(--shadow-high);
  box-sizing: border-box;
  position: absolute;
  color: black;
  max-width: 1100px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  @media screen and (max-width: 760px) {
    max-width: 350px;
    padding: 1rem;
    margin: 6rem 0 7rem 0.7rem;
  }

  .close-modal {
    border: none;
    background-color: transparent;
    cursor: pointer;
    float: right;
  }

  .upgrade {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    text-align: center;
    padding-top: 10px;
    @media screen and (max-width: 760px) {
      display: block;
      margin: 0 auto;
    }

    .upgrade-basic,
    .upgrade-premium {
      position: relative;
      width: 260px;
      height: 346px;
      border: 1px solid #48647f;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 760px) {
        margin: 1rem 0;
      }
    }

    .sticker {
      position: absolute;
      transform: matrix(1, 0, 0, 1, 1, -1);
      right: 0;
      top: 0;
      border-radius: 12px;
      overflow: hidden;
    }

    h1 {
      font-weight: 600;
      font-size: 34px;
      font-family: Poppins;
      font-style: normal;
    }
    // .team-plan-wrap{
    //   position: absolute;
    // }
    .team-plan {
      background-color: #2eb790;
      border-radius: 11.3px 11.3px 0px 0px;
      position: absolute;
      bottom: 0;
      padding: 8px 0;
      width: 100%;
      transform: matrix(1, 0, 0, -1, 0, 0);

      .current-plan {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        transform: scale(1, -1);
        display: block;
        color: #fff;
      }
    }
  }
}

:root {
  --speed: 500ms;
}

.team-status-modal-container {
  position: absolute;
  min-width: 140px;
  margin-top: 0.2rem;
  padding: 5px 3px;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  box-shadow: var(--shadow-high);
  right: 0;
  transform: translateX(50%);

  @media only screen and (max-width: 760px) {
    transform: translateX(0%);
  }

  .addons {
    width: 100%;
  }

  hr {
    border-top: 1px solid #d8d8d8;
    border-bottom: none;
    border-left: none;
    border-right: none;
    width: 100%;
  }
}

.close-button {
  position: absolute;
  right: 0;
  top: 10px;
}

.modal-report-input-group-container {
  display: flex;
  gap: 40px;
}

.modal-report-mobile-input-group-container {
}

.modal-report-input-container {
  margin-top: 20px;
}

.modal-report-input-dropdown {
  width: 300px;
}

.modal-report-title {
  color: black;
  font-family: "Open Sans";
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 8px;
  // font-family: "Poppins" !important;
}

.modal-report-attachment-title {
  color: black;
  font-family: "Open Sans";
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.modal-report-attachment-title-container {
  margin-bottom: 40px;
  margin-top: 12px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.modal-report-item-content {
  color: #001b11;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  width: 300px;
}

.modal-preview {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 11px;
  > input {
    width: calc(100% - 24px);
    height: 100%;
    background-color: transparent;
    padding: 0;
    padding-right: 10px;
    box-sizing: border-box;
    outline: none;
    border: none;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #001b11;
  }
}

.modal-preview-report {
  background-color: white;
}

.clickable {
  cursor: pointer;
}

.resetPass {
  margin-left: 24px;
  margin-bottom: 0px;
}