// Contact page
.container--contact_page_wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: 1fr 1fr / 1fr;

  @media screen and (max-width: 1024px) {
    grid-template: 0.25fr 1fr / 1fr;
  }
}

.container--contact-top {
  padding: 0;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  justify-items: center;
  position: relative;
  background-color: var(--bg-blue);
  width: 100%;
}

.container--contact-bottom {
  background-color: var(--bg-blue);
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  justify-items: center;
  align-items: start;

  @media screen and (max-width: 1024px) {
    grid-template: 1fr 1fr 1fr / 1fr;
    padding-bottom: 16px;
    row-gap: 16px;
  }
}

.container--contact_card {
  display: grid;
  grid-template: 3fr 1fr / 1fr;
  width: 325px;
  height: 192px;
  justify-items: center;
  align-items: center;
  box-shadow: var(--shadow-high);

  @media screen and (max-width: 768px) {
    width: 300px;
    height: 150px;
  }

  @media screen and (max-width: 500px) {
    width: 300px;
    height: 128px;
  }
}

.header--card_heading {
  font-size: 24px;
  font-weight: 500;
}

.header--card_info {
  font-size: 18px;
  font-weight: 500;
  color: var(--font-color-light);
  font-weight: 600;
  text-shadow: 0px 0px 2px var(--font-color-dark-p);
}

.container--contact_card-top {
  border-radius: 5px 5px 0px 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: var(--bg-white);
  display: grid;
  justify-items: center;
  align-items: center;
}

.container--contact_card-bottom {
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  height: 100%;
  background-color: var(--accent-main);
  display: grid;
  justify-items: center;
  align-items: center;
}