.container--site_info_wrapper {
  display: grid;
  grid-template: 1fr / 1fr;
  padding: 0px 16px 16px 16px;
  justify-self: left;
  margin: auto;
  background-color: #f6f6f6;
  & * {
    line-height: 1.85;
  }
  & ul {
    margin-top: 1rem;
    & + p {
      margin-top: 0.5rem;
    }
  }
  & li {
    list-style-type: none;
    position: relative;
    padding-left: 1rem;
    margin-bottom: 0.25rem;
    line-height: 1.75;
    &::before {
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      background-color: #021821;
      border-radius: 50%;
      left: 0;
      top: 0.7rem;
    }
  }
}

.header--site_info_title {
  font-size: 30px;
  padding: 32px 0 16px 0;
  text-align: left;
}

.container--site_scroll_info {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 12px;
}

.text--site_header {
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  margin: .5rem 0 1.5rem;
}

.text--section-title{
  margin-top:2rem;
  text-transform: uppercase;
}

.text--site_important {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
  font-weight: bold;
  // text-transform: ;
}

.text--site_paragraph {
  font-size: 16px;
  // line-height: 1.5;
}

.privacy--page-list {
  font-size: 16px;
  margin-left: 4em;
}

.makeGreen {
  color: none;
}
