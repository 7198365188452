.blogroll-box {
  padding-bottom: 50px;
}

.blogroll--container {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.blogroll--link-box {
  max-width: 360px;
  text-decoration: none;
  color: inherit;
  display: block;
  position: relative;
  cursor: pointer;
}

.blogroll-image {
  width: 360px;
  height: auto;
  margin-top: 4rem;
}

@media only screen and (max-width: 768px) {
  .blogroll-box {
    margin: 0;
  }
  .blogroll-image {
    width: 100%;
  }
}

.container--blog_page_wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: auto 1fr / 1fr;
  row-gap: 48px;
}

.container--blog_page-top {
  display: grid;
}

.container--blog_page-main {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 32px;
  padding-bottom: 48px;

  @media screen and (max-width: 768px) {
    grid-template: auto 1fr / 1fr;
  }
}

.container--blog_left {
  display: grid;
  justify-items: center;

  @media screen and (max-width: 768px) {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    margin-bottom: 64px;
  }
}

.container--blog_right {
  display: grid;
  row-gap: 64px;
  padding: 0 16px;

  @media screen and (max-width: 768px) {
    grid-row: 2 / -1;
    grid-column: 1 / -1;
  }
}

.header--blog_main {
  font-size: 24px;
}

// Blog component styles
.container--post_wrapper-background_image {
  background-color: black;
  background: url("/blog_feature_1920.png") no-repeat;
  background-size: cover;
  padding: 256px;

  @media screen and (max-width: 1280px) {
    background: url("/blog_feature_1280.png") no-repeat;
    padding: 128px;
  }

  @media screen and (max-width: 640px) {
    background: url("/blog_feature_640.png") no-repeat;
    padding: 64px;
  }
}

.container--post_content_wrapper {
  display: grid;
  grid-template: auto auto auto / 1fr;
  row-gap: 16px;
  border-left: 5px solid var(--accent-main);
  padding-left: 32px;
}

.container--post_content_wrapper-feature {
  @extend .container--post_content_wrapper;
  border-left: 0px solid var(--accent-main);
  padding-left: 0px;
}

.header--blog_post_source {
  font-size: 16px;
  opacity: 0.75;
  font-weight: 100;
}

.header--blog_post_source-feature {
  opacity: 0.75;
  font-weight: 100;
  font-size: 16px;
  text-shadow: 0px 0px 15px var(--font-color-dark);
  color: var(--font-color-light);
}

.header--blog_post_title {
  font-size: 24px;
  color: var(--font-color-dark);
  max-width: 30em;

  @media screen and (max-width: 1280px) {
    font-size: 20px;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }
}

.header--blog_post_title-feature {
  font-size: 60px;
  color: var(--font-color-light);
  text-shadow: 0px 0px 15px var(--font-color-dark);

  @media screen and (max-width: 1280px) {
    font-size: 48px;
  }

  @media screen and (max-width: 640px) {
    font-size: 30px;
  }
}

.link--blog_title_link {
  @extend .header--blog_post_title;
}

.link--blog_title_link-feature {
  @extend .link--blog_title_link;
  @extend .header--blog_post_title-feature;
  text-shadow: 0px 0px 15px var(--font-color-dark);
}

.header--blog_post_info {
  font-size: 16px;
  font-weight: 400;
}

.header--blog_post_info-feature {
  @extend .header--blog_post_info;
  color: var(--font-color-light);
  text-shadow: 0px 0px 15px var(--font-color-dark);
}

.link--blog_author_link {
  color: var(--accent-main);
}

.link--blog_author_link-feature {
  color: var(--font-color-light);
}

.span--blog_date {
  font-size: 16px;
}

.span--blog_date-feature {
  @extend .span--blog_date;
  color: var(--font-color-light);
  text-shadow: 0px 0px 15px var(--font-color-dark);
}

//Blog page post styles

.container--blog_post_page_wrapper {
  max-width: 900px;
  height: 100%;
  display: grid;
  box-sizing: border-box;
  grid-template: 1fr / 1fr;
  padding: 16px;
  padding-bottom: 5px;
  justify-items: center;
}

.container--blog_post {
  display: block;
  width: 100%;
}

// .header--blog_post_page_title {
//   text-align: center;
//   font-size: 30px;
//   max-width: 25em;
//   margin: auto;
// }

// .text--post_info {
//   text-align: center;
//   font-size: 16px;
// }

// .span--post_date {
//   font-size: 16px;
//   opacity: .75;
// }

.container--post_content {
  margin: 8px auto 0 auto;
  width: 100%;
  max-width: 768px;
  display: grid;
  box-sizing: border-box;
}

.text--post_paragraph {
  line-height: 1.5;
  font-size: 15pt;
  margin: 8px 24px;
  //text-indent: 50px;
}

.text--post_bullet {
  margin-left: 25px;
  margin-right: 25px;
  padding-left: 20px;
  padding-right: 20px;
  text-indent: -20px;
}

.text--post_bullet-alt {
  margin-left: 25px;
  margin-right: 25px;
}

.text--post-center {
  @extend .text--post_paragraph;
  text-align: center;
}

.text--post_paragraph-bold {
  @extend .text--post_paragraph;
  font-weight: bold;
}

.header--post_title {
  text-align: center;
  font-size: 24px;
  margin: 16px 0;
  line-height: 1.25;
}

.header--post_title-left {
  @extend .header--post_title;
  text-align: left;
}

.img--blog_post_img_container {
  display: flex;
  justify-content: center;
}

.img--blog_post_infographic {
  margin: 16px 0;
  max-width: 200px;
  max-height: 400px;
}

.img--blog_post_image_large {
  margin: 0px;
  max-width: 350px;
  // max-height: 250px;
}

.img--blog_post_image_medium {
  margin: 0px;
  max-width: 330px;
  // max-height: 50%;
}

.blog--image_whywork_ideation {
  margin: 16px 0;
  max-width: 430px;
  max-height: 100%;
}

.img--blog_post_image_small {
  margin: 0px;
  max-width: 275px;
  // max-height: 100%;
}

.img--blog_img_caption {
  font-style: italic;
  font-size: 11px;
  text-align: center;
  margin-top: -8px;
}

a:link.blogpost--link-references {
  color: #2eb790;
  text-decoration: none;
}

a:visited.blogpost--link-references {
  color: #48647f;
  text-decoration: none;
}

a:hover.blogpost--link-references {
  color: #228d6d;
  text-decoration: underline;
}

a:active.blogpost--link-references {
  color: #2eb790;
  text-decoration: none;
}

.blog--nav_container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.blog--navigation_previous_arrow {
  float: left;
  cursor: pointer;
}

.blog--navigation_previous_text {
  float: right;
  margin-left: 10px;
  color: #2eb790;
}

.blog--navigation_next_arrow {
  float: right;
  margin-right: 15px;
  cursor: pointer;
}

.blog--navigation_next_text {
  float: left;
  margin-right: 10px;
  color: #2eb790;
}

.blog--navigation_goback--main {
  color: #2eb790;
  margin-left: 2rem;
  cursor: pointer;
  font-family: Poppins;
}
