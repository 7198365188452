.subteam-container {
  padding: 40px 10px;
  max-height: 500px;
  // overflow-y: auto;

  .subteam-card-link {
    position: absolute;
    border: none;
    background-color: transparent;
    top: 30px;
    cursor: pointer;
    right: 30px;
  }
  .hide-scroll {
    overflow-y: scroll;
    /* Hide scrollbar */
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /* For Firefox */
  }
  .create-team-button {
    border: none;
    background-color: transparent;
  }

  .upload-button {
    position: absolute;
    visibility: hidden;
  }

  .subteam-upload-button {
    border: 1px solid #d8d8d8;
    border-radius: 12px;
    padding: 8px 55px;
    background-color: transparent;
    cursor: pointer;
  }

  .subteam-team-name {
    margin-top: 3rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }

  .subteam-input-name {
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    margin-top: 10px;
  }

  .subteam-save-button {
    background-color: #2eb790;
    border-radius: 4px;
    color: white;
    margin-top: 35px;
    padding: 10px;
    cursor: pointer;
    border: none;
    width: 100%;
  }

  .subteam-svg {
    img {
      width: 571px;
      height: 419px;
    }
  }
}
