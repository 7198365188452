.container--process_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text--process_info {
  font-size: 24px;
}

.text_link_expired {
  margin-left: 24px;
  margin-top: 32px;
  margin-right: 24px;
}