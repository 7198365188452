.rdw-editor-main {
  background: #fff;
  max-width: 380px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  max-height: 56px;
}
.rdw-editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
  position: relative !important;
}
.rdw-editor-toolbar,
.rdw-option-wrapper {
  background: 0 0 !important;
  border: none !important;
}
.rdw-option-wrapper:hover {
  box-shadow: none !important;
}
.rdw-emoji-modal {
  top: -205px !important;
  left: -235px !important;
}
@media screen and (max-width: 768px) {
  .rdw-emoji-modal {
    left: 0 !important;
    top: -215px !important;
  }
}
.rdw-image-modal,
.rdw-link-modal {
  top: -240px !important;
  z-index: 1000;
}
.rdw-link-modal-target-option {
  display: none;
}
.DraftEditor-root {
  padding-left: 12px;
  padding-right: 44px;
}
.DraftEditor-editorContainer {
  line-height: 22px;
  max-height: 100px;
}
.customEmoji {
  font-size: 30px;
}
.floatingChat .rdw-option-wrapper {
  margin: 0;
}
