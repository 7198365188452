.container--add_user_input_wrapper {
  margin: 24px 0 0 0;
}

.form--team_input_wrapper {
  display: grid;
  grid-template: 1fr auto / auto auto 1fr;
  margin: 8px 0 0 0;
}

.input--users_add_input {
  box-sizing: border-box;
  display: block;
  width: 390px;
  height: 42px;
  border-radius: 5px 0 0 5px;
  padding: 4px 16px;
  font-size: 16px;
  box-shadow: var(--shadow-mid);
}

.button--add_button {
  background-color: var(--accent-main);
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  padding: 4px 24px;
  height: 42px;
  box-shadow: var(--shadow-mid);
}

.text--add_error {
  grid-row: 2 / -1;
  grid-column: 1 / 3;
}

.container--search_for_members {
  display: grid;
  margin-top: 4px;
}
