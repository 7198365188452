.header_wrapper-dark {
  background-color: var(--bg-darkestblue);
}

.header_wrapper-light {
  background-color: rgba(0, 0, 0, 0);
}

.container--header {
  width: 100vw;
  display: grid;
  grid-template: 1fr / auto 1fr;
  align-items: center;
  box-sizing: border-box;
  padding: var(--page-spacing);
  padding-top: 16px;
  padding-bottom: 16px;
  @media only screen and (max-width: 760px) {
    display: flex;
    justify-content: space-around;
  }
}

.container--header-darker {
  @extend .container--header;
}

.container--header-fixed {
  width: 100%;
  display: grid;
  grid-template: 1fr / auto 1fr 1fr auto;
  align-items: center;
  box-sizing: border-box;
  padding: var(--page-spacing);
  padding-top: 16px;
  padding-bottom: 16px;
  grid-row: 1 / 2;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.25s ease-in;
  @media only screen and (max-width: 760px) {
    display: flex;
    justify-content: space-around;
  }
}

.centered-container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
}

.container--header-fixed-darker {
  @extend .container--header-fixed;
  background-color: var(--bg-darkestblue);
  transition: background-color 0.5s ease-in;
}

.container--header-fixed-darker.header-light {
  @extend .container--header-fixed;
  background-color: var(--bg-darkestblue);
  transition: background-color 0.5s ease-in;
}

.header-base {
  position: absolute;
  width: 100%;
  transition: box-shadow 1s ease-in-out, background-color 1s ease-in-out;
  z-index: 1000;
}

.header-light,
.header-dark {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  background-color: rgb(
    255,
    255,
    255
  ); // these two will only be active when scrolled down the page, when position is greater than zero.
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.09);
  z-index: 1002;
  width: 100%;
  top: 0px;
}

.hamburger-Navbar {
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 73px;
  right: -100%;
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.15));
  width: 70%;
  transition: right 0.5s ease-in-out;
  z-index: 9999;
}

.hamburger-Navbar.showNavbar {
  z-index: 9999;
  right: 0;
}

.hamburger-Menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.space-10 {
  padding: 5px;
}

.hamburger-Button {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  border: none;
  margin: 13px 0;
}

.hamburger-Button.bordered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2% 20%;
  border: 2px solid #2eb790;
  box-sizing: border-box;
  border-radius: 4px;
}

.hamburger-Button.bg-green {
  background-color: #2eb790;
}

.hamburger-Typography.color-white {
  color: white;
}

.hamburger-Typography {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #2eb790;
}

.hamburger-ProfileIcon {
  width: 27px;
  height: 27px;
  border-radius: 50%;
}

.container--logo_wrapper {
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: 5vw !important;
  cursor: pointer;
  #small {
    display: none;
    @media screen and (max-width: 760px) {
      display: block;
    }
  }
  #big {
    display: block;
    @media screen and (max-width: 760px) {
      display: none;
    }
  }
}

.link--pagenav-logo {
  color: #2eb790;
  margin-left: 15px;
  .logo-black {
    color: black;
  }
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
}

.link--pagenav-logo span {
  font-size: 24px;
  @media only screen and (max-width: 376px) {
    font-size: 16px !important;
  }
}

.container--nav-right {
  grid-column: 4 / -1;
  justify-self: right;
  display: grid;
  grid-template: 1fr / auto auto auto auto;
  align-items: center;
  // @media screen and (max-width: 885px) {
  //   display: flex;
  //   width: "12rem";
  // }
}

.nav-right_search {
  display: inline-block;
  border-radius: 4px;
  color: #0d2923 !important;
  padding: 5.5px !important;
  font-family: "Poppins" !important;
  letter-spacing: 1px;
  width: 100px;
  margin-right: 15px;
  border: 1px solid rgb(223, 217, 226) !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.08);
}

.nav-right_search:hover {
  background-color: #2eb790 !important;
  color: #ffffff !important;
  border: 1px solid rgb(192, 255, 225) !important;
}

.button--toggle-nav {
  background: none;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar--container_token {
  position: relative;
  cursor: pointer;
  width: auto;
  height: auto;
  margin-right: 0.5vw;
}

.navbar--button_wrapper {
  display: flex;
  align-items: baseline;
}

.nav-right_navName {
  margin-left: 8px;
  color: #383838;
  font-family: Poppins;
  font-size: 16px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
}

.image--header_avatar {
  height: 35px;
  border-radius: 50%;
}

.svg--user_icon {
  height: 35px;
}

.avatar--profile_image {
  &:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
}

.header--logout_button {
  display: block;
  margin-left: 1rem !important;
  margin-right: 1.5rem !important;
  color: black !important;
  padding: 4px !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  grid-column: auto / 12;
  grid-row: 2fr / auto;
  letter-spacing: 1px !important;
  width: 100px;
  border: 1px solid rgb(223, 217, 226) !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.08);
  text-transform: capitalize !important;
}

.header--logout_button:hover {
  background-color: #2eb790 !important;
  color: #ffffff !important;
  border: 1px solid rgb(192, 255, 225) !important;
}

.header--signup-signin_buttons {
  box-shadow: 1px 1px 1px 1px rgba(80, 80, 80, 0.13);
  font-size: 16px;
  //pending
  border-radius: 8px;
  padding: 6px 16px;
  letter-spacing: 1px;
}

.c-newMessage:before {
  content: "";
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}

// .muiButton-outline-override {
//   padding: 5px 15px;
//   border-color: #2eb790;
//   border-width: 2px;
//   overflow: hidden;
//   box-sizing: border-box;
// }
