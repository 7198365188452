.container--notfoundpage {
  position: relative;
  height: 1404px;
  margin-top: 191px;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    margin-top: 100px;
    height: 1100px;
  }

  @media screen and (max-width: 600px) {
    height: 850px;
  }

  @media screen and (max-width: 415px) {
    height: 700px;
  }

  @media screen and (max-width: 320px) {
    height: 600px;
  }

}

.skytop, .skybottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  margin: 0 auto;

  @media screen and (max-width: 1400px) {
    width: 140%;
    left: -200px;
  }

  @media screen and (max-width: 1000px) {
    width: 170%;
    left: -250px;
  }

  @media screen and (max-width: 600px) {
    width: 180%;
    left: -200px;
  }

  @media screen and (max-width: 400px) {
    width: 190%;
    left: -200px;
  }

}

.skytop {
  z-index: -1;
}

.skybottom {
  top: 59px;
  z-index: 0;

  @media screen and (max-width: 800px) {
    top: 40px;
  }

  @media screen and (max-width: 600px) {
    top: 30px;
  }

}

.cloudleft-container {
  position: relative;
  top: 130px;
  left: 19vw;
  margin: 0 auto;
  z-index: 1;

  @media screen and (max-width: 800px) {
    top: 70px;
  }

  @media screen and (max-width: 600px) {
    top: 30px;
    left: 10vw;
  }

  @media screen and (max-width: 400px) {
    top: 22px;
    left: 5vw;
  }

}

.cloudleft {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;

  @media screen and (max-width: 800px) {
    width: 180px;
  }

  @media screen and (max-width: 400px) {
    width: 130px;
  }
}

.sadface {
  position: absolute;
  top: 50px;
  left: 130px;
  margin: 0 auto;
  z-index: 1;

  @media screen and (max-width: 800px) {
    width: 40px;
    left: 92px;
    top: 45px;
  }

  @media screen and (max-width: 400px) {
    width: 30px;
    left: 70px;
    top: 40px;
  }
}

.cloudcenter {
  position: absolute;
  top: 70px;
  left: 53vw;
  margin: 0 auto;
  z-index: 1;

  @media screen and (max-width: 800px) {
    width: 100px;
    top: 130px;
    right: 5px;
  }

  @media screen and (max-width: 600px) {
    width: 90px;
    top: 90px;
    right: 5px;
  }

  @media screen and (max-width: 400px) {
    width: 80px;
    top: 80px;
    right: 2px;
  }

}

.cloudright {
  position: absolute;
  top: 248px;
  left: 70vw;
  margin: 0 auto;
  z-index: 1;

  @media screen and (max-width: 1000px) {
    top: 200px;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.bottomcloud {
  position: absolute;
  top: 286px;
  left: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 1;

  @media screen and (max-width: 800px) {
    width: 130%;
    top: 200px;
  }

  @media screen and (max-width: 600px) {
    top: 150px;
  }

  @media screen and (max-width: 400px) {
    top: 130px;
  }

}

.container--notfoundpage_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    height: 223px;
    margin-top: 460px;
    margin-bottom: 4px;
    font-family: var(--notfound-font);
    font-style: normal;
    font-weight: 600;
    font-size: 280px;
    line-height: 430px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 20px;
    color: #97D1C5;
    text-shadow: 13px 0 #2eb790;
    z-index: 2;

    @media screen and (max-width: 800px) {
      font-size: 250px;
      margin-top: 400px;
    }

    @media screen and (max-width: 600px) {
      height: 100px;
      margin-top: 300px;
      margin-bottom: 4px;
      font-family: var(--notfound-font);
      font-style: normal;
      font-weight: 600;
      font-size: 140px;
      line-height: 430px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 10px;
      color: #97D1C5;
      text-shadow: 8px 0 #2eb790;
    }

    @media screen and (max-width: 415px) {
      margin-top: 220px;
      letter-spacing: 8px;
      font-size: 100px;
    }

    @media screen and (max-width: 320px) {
      margin-top: 180px;
      letter-spacing: 8px;
      font-size: 100px;
    }

  }

  p {
    height: 48px;
    font-family: var(--notfound-font);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #515151;
    z-index: 2;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }

    @media screen and (max-width: 415px) {
      font-size: 13px;
    }

  }

  button {
    margin-top: 92px;
    width: 244px;
    height: 92px;
    background: #2eb790;
    border-radius: 80px;
    border: none;
    font-family: var(--body-font);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    z-index: 2;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      font-size: 14px;
      width: 180px;
      height: 80px;
    }

    @media screen and (max-width: 415px) {
      margin-top: 20px;
    }

  }
}
