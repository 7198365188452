.team-summary-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-summary-viewer-title {
  width: 100%;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 2rem;

  span {
    font-size: 12px;
    font-weight: 400;

  }
}
.team-summary-viewer-title-new {
  width: 100%;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 2rem;

  span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0.5rem;
  }
}

.team-summary-viewer-image {
  width: 100%;
  height: 235px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin-bottom: 2.5rem;
}

.team-summary-title {
  span {
    color: #2eb790;
    letter-spacing: 0.15px;
  }
}

.team-summary-viewer-created-date {
  color: rgba(#001b11, 0.7);
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  font-weight: 400;
  font-style: italic;
}

.team-summary-viewer-members {
  color: rgba(#001b11, 0.7);
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  font-weight: 400;
  margin-bottom: 2rem;
}

.team-summary-viewer-status {
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  height: 40px;
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  > div {
    display: flex;
    align-items: center;
  }
}

.team-summary-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-summary-editor-item {
  margin-top: 20px;
  width: 100%;
}

.team-summary-editor-item-title {
  color: black;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 8px;
}

.team-summary-editor-item-content {
  color: #001b11;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;

  .team-plan-preview {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d8;

    > span {
      color: #2eb790;
    }
  }

  .team-name-preview {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 11px;
    > input {
      width: calc(100% - 24px);
      height: 100%;
      background-color: transparent;
      padding: 0;
      padding-right: 10px;
      box-sizing: border-box;
      outline: none;
      border: none;
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #001b11;
    }
  }

  .team-image-edit {
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 54px 1fr;
    grid-column-gap: 1rem;

    .team-image-preview {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
    }

    .team-image-upload {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      display: flex;

      justify-content: center;
      align-items: center;
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
        > img {
          margin-right: 10px;
        }
      }
    }
  }

  .team-status-edit {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 11px;
    outline: none;
    cursor: pointer;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  }
}

.team-status-dropdown {
  margin-top: 4px;
  width: 312px;
  padding: 16px;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.team-summary-editor-ops {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

abbr[title] {
  text-decoration: none;
}