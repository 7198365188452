// Manage profiles styles
.form--profile_update {
  display: grid;
  grid-template: repeat(9, auto) / 1fr 1fr;
  grid-gap: 24px;
}
.label--profile_form {
  grid-column: 1 / 2;
  font-family: var(--header-font);
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: var(--font-color-dark);
}
.input--profile_form {
  grid-column: 1 / 2;
  box-sizing: border-box;
  display: block;
  width: 512px;
  padding: 8px;
  border-radius: 5px;
  margin-top: 12px;
  font-size: 18px;
  color: var(--font-color-dark);
}
// Skills group label
.label--form_label_group {
  display: grid;
  grid-template: auto 1fr 1fr 1fr / 1fr auto;
  column-gap: 6px;
  row-gap: 8px;
}
.text--skill_label_text {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  margin: 0;
}
.input--profile_form-skillone,
.input--profile_form-skilltwo,
.input--profile_form-skillthree {
  grid-column: 1 / 2;
}
.input--profile_form-skillone {
  grid-row: 2 / 3;
}
.input--profile_form-skilltwo {
  grid-row: 3 / 4;
}
.input--profile_form-skillthree {
  grid-row: 4 / 5;
}
.button--profile_skill_delete {
  background-color: var(--bg-white);
  color: var(--bg-lightbluethree);
  padding: 0;
  grid-column: 2 / -1;
  width: 48px;
  border-radius: 5px;
  align-self: center;
}
.delete_button_one {
  grid-row: 2 / 3;
}
.delete_button_two {
  grid-row: 3 / 4;
}
.delete_button_three {
  grid-row: 4 / 5;
}
// Manage player profiles styles
.container--table_header-profiles {
  padding: 6px 8px;
  display: grid;
  align-items: center;
  column-gap: 24px;
  grid-template: 48px / 0.75fr 1fr 1fr 100px 100px;
  background-color: var(--bg-white);
  border-radius: 5px;
}
.header--table_heading_text-last {
  grid-column: 4 / -1;
}
.container--players_profile-row {
  padding: 6px 8px;
  @extend .container--table_header-profiles;
  border-radius: 0;
  border-top: 2px solid var(--bg-lightblue);
}
.button--profile_player-edit_left {
  background-color: var(--bg-white);
  grid-column: 4 / 5;
}
.button--profile_player-delete {
  grid-column: 5 / -1;
  background-color: rgba(0, 0, 0, 0);
}
.form--player_update {
  grid-column: 1 / 5;
  display: grid;
  grid-template: 64px / 1fr 1fr 1fr;
  align-items: center;
}
.label--player_form {
  width: 256px;
}
.input--player_form {
  width: 256px;
  padding: 8px;
  border-radius: 5px;
  margin-top: 0px;
  font-size: 18px;
  background-color: var(--bg-lightblue);
  color: var(--font-color-dark);
}
// Manage profile styles
// New Profile layout Styles
.avatar--icon_profile {
  width: 100%;
  height: 25%;
  padding: 8px 0 8px 0;
  box-sizing: border-box;
  @media screen and (max-width: 400px) {
    width: 310px;
  }
}

.view-your-public-profile-link {
  cursor: pointer !important;
  position: relative;
  float: right !important;
  margin-right: 1rem !important;

  @media only screen and (max-width: 500px) {
    margin-right: 2rem !important;
  }
}

.view-your-public-profile-link p {
  color: #2eb790 !important;
  font-family: Poppins;
  font-size: 14px;
}

.avatar--button {
  text-align: center;
  width: 100%;
}
.upload--profile_image {
  padding-left: 2rem !important;
}
@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card--profile_name {
  text-align: center !important;
  margin-top: 15px;
  display: block;
  font-family: "Poppins" !important;
  font-size: 24px;
  color: var(--font-color-dark);
  font-weight: 600 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin: 1rem 0 !important;
  @media screen and (max-width: 768px) {
    margin: 1rem !important;
  }
}

.profile-image_public {
  display: flex;
  justify-content: center;
}

.profile--card_container {
  position: relative;
  text-align: center;

  .profile-image {
    height: 115px !important;
    width: 115px !important;
    margin: 0 auto;

    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.01) 0px 5px 16px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: transparent;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover ~ .edit {
      visibility: visible;
    }
  }
  .avatar--profile_image {
    justify-content: center !important;
    height: 115px !important;
    width: 115px !important;
    &:after {
      content: "\A";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
    }
    &:hover:after {
      opacity: 1;
    }
  }

  .my_file {
    visibility: hidden;
  }
  .edit {
    text-align: center;
    padding-top: 7px;
    padding-right: 7px;
    position: relative;
    left: 5px;
    top: -75px;
    visibility: hidden;
    cursor: pointer;
    &:hover {
      visibility: visible;
    }
  }
}
.avatar--profile_info {
  text-align: center;
}
.profile--card_join_on {
  clear: both;
  text-align: center;
  font-style: italic;
  color: rgba(0, 27, 17, 0.7);
}

.profile--card_join_on_public {
  margin-top: 10px !important;
}

.profile--card_email_public {
  margin-top: 25px !important;
}

.profile--card_public_button {
  margin-top: 30px !important;
}

.profile--card_email {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 16px 0px 5px 48px;
  display: inline-block;
  max-width: 180px;
}
.profile--card {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  background: rgba(91, 182, 146, 0.1);
  border-radius: 4px;
  margin-left: 5px;

  .profile--card_verified {
    position: static;
    font-family: Open Sans;
    float: right;
    font-size: 14.5px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    letter-spacing: 0.25px;
    background-color: #00a7912a;
    color: #2eb790;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.profile--card_unverified {
  position: static;
  font-family: Open Sans;
  float: right;
  margin-bottom: 5px;
  font-size: 14.5px;
  background-color: #ec065e15;
  letter-spacing: 0.25px;
  color: #ec064b;
}

.profile--chips {
  background-color: #7ae4c1 !important;
  padding: auto !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 5px 67px 0px,
    rgba(0, 0, 0, 0.08) 3px 3px 3px 3px;
  font-size: 14px !important;
  font-family: Poppins !important;
  color: #272525d5 !important;
  vertical-align: top !important;
  display: inline-flex;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 5px !important;
}
.profile--chips:hover {
  background-color: #2eb790 !important;
  color: white !important;
}
.MuiChip-deleteIcon {
  margin-left: 5px !important;
  color: #666 !important;
}
.MuiChip-deleteIcon:hover {
  color: white !important;
}
.profile--chips:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
.profile--input_autocomplete {
  max-height: 10rem !important;
  padding: 10px !important;
  max-width: 20rem !important;
}
.profile--input_autocomplete-signup {
margin: 0 !important;
padding: 0 !important;
}
.profile--input_autocomplete_input {
  margin-top: 10px;
  padding: 1px;
}
.profile--card_editbutton {
  padding: 10px 20px;
  background-color: #2eb790 !important;
  border-radius: 24px;
  color: white;
  width: 200px;
  font-size: 14px;
  border: 0;
  margin: 20px 0 0 70px;
}
.profile--info_save_button:hover {
  background-color: #2eb790 !important;
  color: white !important;
}
.profile--input_chips {
  display: inline-flex;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}
.profile--layout_userInfo {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border: 1px solid rgba(0, 27, 17, 0.1);
  border-radius: 5px;
  background-color: #fff;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.profile--layout_ratings_reviews_block {
  margin-top: -25px;
  display: flex;
  flex-direction: column;
  max-width: 822px;
  justify-content: center;
  @media only screen and (max-width: 1140px) {
    margin-bottom: 85px;
  }
}
.close {
  color: red;
}
.hr--dashboard_page_break_profile {
  margin: 0;
  background-color: #f5f4f2;
  height: 2px;
  border: none;
}

.MuiBadge-badge {
  margin-top: -2px !important;
}

.save-icon-profile-container {
  width: 25px !important;
  position: absolute;
  top: 25px;
  right: -13px;
  opacity: 0;
  transition: all 0.5s ease;

  &.saved {
    opacity: 1;
  }

  @media screen and (max-width: 959px) {
    left: 230px;
    top: 20px;
  }
}
.save-icon-profile-bio-container {
  width: 25px !important;
  position: absolute;
  top: -20px;
  right: -13px;
  opacity: 0;
  transition: all 0.5s ease;
  &.saved {
    opacity: 1;
  }
  @media screen and (max-width: 959px) {
    left: 230px;
  }
}
.save-icon-profile {
  color: #2eb790;
}

.yourProfileOrMyProfile {
  position: relative;
  text-align: center;
  background-color: #2eb790;
  height: 25px;
  margin-top: -8px;
  color: ghostwhite;
  border-radius: 0px 0px 5px 5px;
  @media only screen and (max-width: 768px) {
    max-width: 91%;
    margin-left: 14px;
  }
}

.publicProfileUserInfo {
  background: rgba(0, 0, 0, 0.08);

  color: #1f1d1d;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 3px 0px 6px;
  width: calc(100% - 9px) !important;
}

.publicProfileBioInfo {
  background: rgba(35, 179, 147, 0.185);
  text-shadow:"0px 0px 0px #000000";
  box-sizing: "border-box";
  border-radius: "none";
  border-color: "#ffffff !important";
  color: #1f1d1d;
  outline:'none';
  resize: "none";
  max-width: 30ch !important;
  padding: 0px 3px 0px 6px;
}

.public--label {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.pub_container {
  margin-bottom: -5px !important;
}

.gotSkillz {
  background: rgba(242, 75, 75, 0.1);
  color: #f24b4b;
  font-size: 15px !important;
  max-width: fit-content !important;
  padding-right: 3px !important;
}

.publicProfile--card_container {
  position: relative;
  text-align: center;

  .profile-image {
    height: 115px !important;
    width: 115px !important;
    margin: 10px 0px 10px 80px;

    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.01) 0px 5px 16px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: transparent;
    transition: all 0.3s ease;
  }
  .avatar--profile_image {
    justify-content: center !important;
    height: 115px !important;
    width: 115px !important;
  }
}

.public--profile_skills_title {
  margin-bottom: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin-right: 50px !important;
}

.edit--profile_button {
  position: relative !important;
  float: right !important;

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 50%;
    left: 2%;
    transform: translate(-50%, -50%);
    margin-top: 3.5%;
  }
}

.mobile--token_view_profile {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
    position: relative;
    margin-top: 40px;
    margin-bottom: -40px;
    margin-left: 63px;
    background-color: #2eb790;
    font-size: 14.25px;
    max-width: 150px;
    padding: 2;
    opacity: 0.8;
    color: #ffffff;
    border-radius: 5;
    text-align: center;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.5);
  }
}
.makeStyles-userList-10{
  z-index: 99999;
}

.profile-left-container {
  width: 822px
}