.navbar--increment_icon {
  z-index: 9;
  position: relative;
  bottom: 7px;
  right: -3px;
}

.token--token_menu {
  z-index: -1;
  position: fixed;
  background-color: rgb(255, 255, 255);
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.15));
  transition: 0.5s ease-in-out;
  top: -1000px;
  right: 27.5%;
  min-height: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  align-items: center;
  @media only screen and (max-width: 760px) {
    width: calc(100% - 40px);
    transform: none;
    right: 0;
  }
}

.token--token_menu.show {
  z-index: 9999;
  min-height: 361px;
  top: 73px;
}

.token--token_menu.hide {
  z-index: -1;
  min-height: 0;
  top: 0;
  top: -1000px;
}

.navbar--tokens {
  z-index: 9;
  position: absolute;
  bottom: 11px;
  left: 49%;
  bottom: 11px;
  color: #ffffff;
  font-weight: 700;
  transform: translate(-33%, -0%);
}

.navbar--ticket_svg {
  opacity: 0.9;
}

.navbar_ticket_shape {
  margin-right: 10px;
}

.token--container {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #ffffff;
}

.token--up_button {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}

.token--card {
  width: 281px;
  min-height: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  padding-bottom: 1rem;
  overflow-x: clip;
  margin-top: 1rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  overflow-y: visible;
}

.token--background_text_field {
  height: 89px;
  left: 26.88%;
  right: 24.69%;
  top: 45px;
  background: #ffffff;
  position: absolute;
  border-radius: 20px;
}

.token--background_text_field.disabled {
  background: #d8d8d8;
}

.token--card::after {
  content: "";
  position: absolute;
  background-image: url("/images/svg/back.svg");
  width: 400px;
  height: 300px;
  left: -40%;
  z-index: -1;
}

.token--token_label {
  margin-top: 5%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ffffff;
}

.token--styled_subtotal {
  font-size: 28px;
  font-weight: 600;
  font-family: Poppins;
  margin-top: 1rem;
  padding-left: 25px;
  margin-bottom: "5px";
}

.token-counter_number {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #2eb790;
}

.token-counter_container {
  border: none;
  border-bottom: 1px solid #d8d8d8;
  background: none;
  width: 86px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #2eb790;
}

.token-counter_container.disabled {
  background: #d8d8d8;
}

.token--total_container {
  position: relative;
  margin-top: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.token--subtotal {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000000;
}

.token--amount_duo {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.15px;
  color: #000000;
}

.token--checkout-button {
  background: #2eb790;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 10px 60px;
}

.token--token_free {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  letter-spacing: 0.15px;
  color: #e4475b;
}

.token--new_balance {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  margin-top: 1rem;
  color: #000000;
}

.token--input {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 8px;
  width: 70%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000000;
  padding: 10px 20px;
}

.token--check_button_green {
  border: 1px solid #2eb790 !important;
  box-sizing: border-box;
  outline: none;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 20%;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #2eb790 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.token--check_button_gray {
  border: 1px solid #d8d8d8 !important;
  box-sizing: border-box;
  width: 20%;
  outline: none;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15px;
  color: #d8d8d8 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.token--discount_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 281px;
}

.token--strip_button_container {
  margin-top: 1rem;
}

.token--not_valid {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #e4475b;
}

.token--valid {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #2eb790;
}
