.managers-tab {
  height: 100%;
  padding: 40px 70px;
  display: flex;
  flex-wrap: wrap;
  gap: 54px;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 8px;
    background: transparent;
  }

  @media only screen and (max-width: 760px) {
    padding: 0px;
    margin: 2rem 2rem 0;
  }
}
