.team-members-container {
  position: relative;
  margin: 0 auto;
  padding: 40px 70px;

  .MuiGrid-container {
    // @media only screen and (max-width: 1140px) {
    //   justify-content: center !important;
    // }
  }

  @media only screen and (max-width: 760px) {
    padding: 1rem;
    max-height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .mobile-grid {
      margin: 0 -1rem 0 !important;
    }
  }
}

.team-members-container-subteam {
  position: relative;
  margin: 0 auto;
  padding: 40px 70px;

  @media only screen and (max-width: 790px) {
    padding: 1rem;
    max-height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .mobile-grid {
      margin: 0 -1rem 0 !important;
    }
  }
}

.member-card {
  width: 256px;
  height: 280px;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  box-sizing: border-box;
  transition: transform 350ms;
  transition: box-shadow 0.5s;
}

.member-card-header {
  width: 100%;
  height: 44px;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal;
  font-size: 16px;

  &.creator {
    background-color: #2eb790;
  }

  &.manager {
    background-color: #6380f2;
  }

  &.declined {
    background-color: #e4475b;
  }

  &.pastMember {
    background-color: #e4475b;
  }

  &.pending {
    background-color: #f8c22b;
  }
}

.member-card-body {
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  &.has-status {
    border-radius: 0 0 12px 12px;
  }
}

.card-info-body {
  margin-top: 20px;
}

#member-option {
  @media only screen and (max-width: 760px) {
    // display: none
  }
}

.status-member-edit {
  position: absolute;
  top: 2rem;
  right: 20px;
  cursor: pointer;
}

.member-edit {
  position: absolute;
  top: 3rem;
  right: 20px;
  cursor: pointer;
}

.member-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin-bottom: 20px;
}

.member-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  .member-name {
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.15px;
    color: #001b11;
    text-align: center;
    margin: 0;
    margin-bottom: 3px;
  }

  .member-position {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #001b11;
    text-align: center;
    margin: 0;
    margin-bottom: 3px;
  }

  .member-date {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: rgba(#001b11, 0.7);
    text-align: center;
    margin: 0;
  }
}
.install-teammateme {
  border-radius: 8px !important;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 1px;
}
