.view-application {
  padding: 0;
  padding-bottom: 1rem;
  border-radius: 8px;

  @media only screen and (max-width: 760px) {
    width: 350px;
    max-height: 600px;
    overflow: auto;
  }

  .close-button {
    padding: 1rem;
    border: none;
    background-color: transparent;
    margin-right: 10px;
    float: right;

    @media screen and (max-width: 760px) {
      padding: 0;
      margin: 0;
    }
  }

  .view-applicant_modal_container {
    text-align: center;
    padding: 5px;
  }

  .view-applicant_inner_modal {
    padding: 0 1.5rem;
    width: auto;
    box-sizing: border-box;

    @media screen and (max-width: 760px) {
      padding: 0 1rem;
      min-width: 300px;
    }

    .member-avatar {
      display: inline-block;
      vertical-align: middle;
      @media screen and (max-width: 760px) {
        display: block;
        margin: 0 auto;
      }
    }

    .member-info {
      margin-left: 2rem;
      display: inline-block;
      font-size: 24px;
      font-weight: 700;
      @media screen and (max-width: 760px) {
        display: block;
        margin: 0 auto;
      }
    }

    hr {
      width: 100%;
      border: 1px solid #f4f5f2;
    }
  }

  .grid {
    margin-top: 1rem;
  }

  .rating-section {
    margin-top: 1rem;

    span {
      font-weight: 700;
      letter-spacing: 0.15px;
    }
  }

  .rating {
    margin: 1rem 0 0.5rem;
  }

  .view-reviews {
    margin: 1.5rem;

    @media screen and (max-width: 760px) {
      display: block;
      margin: 2rem 0;
      text-align: left;
      font-weight: 700;
    }
  }
  .application-letter_container {
    padding: 0 2rem;

    @media screen and (max-width: 760px) {
      padding: 0;
    }
  }

  .application-letter_box,
  .review-box {
    margin-top: 0.5rem;
    min-height: 50px;
    background: rgba(245, 244, 242, 0.8);
    border-radius: 4px;
    color: #4a5c55;
    letter-spacing: 0.15px;
    font-weight: 700;
    padding: 1rem;
    font-size: 14px;
    text-align: left;
  }

  .review-box {
    max-height: 100px;
    overflow: auto;
  }

  .rating-created {
    color: rgba(0, 27, 17, 0.5);
    font-weight: 400;
  }

  .applicant-review {
    margin: 1rem 0;
    font-weight: 700;
  }

  .button {
    margin-top: 1.5rem;
    padding: 0 2rem;

    @media screen and (max-width: 760px) {
      margin-top: 1rem;
      padding: 0;
    }

  }

  .button-preview {
    margin-top: 0.5rem;
    padding: 0 2rem;

    @media screen and (max-width: 760px) {
      margin-top: 1rem;
      padding: 0;
    }
  }

  .member-skillsets {
    margin-bottom: 1rem;
  }
}
