.team-agreement-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 52px 72px;
  overflow-y: auto;

  @media only screen and (max-width: 1140px) {
    padding: 1rem;
  }
}

.editing-padding {
  padding: 0px 30px;
}

.team-agreement-rules-title {
  font-family: Poppins;
  font-weight: bold;
  font-size: 34px;
  line-height: 51px;
  letter-spacing: 0.15px;
  color: black;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 5px;
  display: inline-block;
}

.team-agreement-rules {
  float: right;
}

.team-agreement-rules-edit-icon {
  color: #2eb790;
  cursor: pointer;
}

.team-agreement-rules-description {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 50px;
}

.team-agreement-rule-item {
  margin-bottom: 24px;
  &-title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.15px;
    margin: 0;
    margin-bottom: 24px;
    color: black;
  }
  &-description {
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
    margin: 0;
    color: black;
  }
}

.team-agreement-form {
  .agreement-form-grid {
    display: grid;
    grid-template-columns: 2fr 0.5fr;

    @media only screen and (max-width: 1140px) {
      grid-template-columns: 1.5fr 1fr;
    }
  }

  .agreement-remove-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 4.2rem;
  }

  input {
    height: 40px;
    margin: 15px;
    width: 100%;
    font-weight: 600;
    padding: 0 10px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    font-size: 16px;
    vertical-align: middle;

    &::placeholder {
      color: #a9a9a9;
    }
  }
}

.team-agreement-add-rule {
  width: 100%;
}

.div-button-agreement {
  margin: 2rem 0;

  .cancel-button {
    margin-right: 1rem;
  }
  @media only screen and (max-width: 1140px) {
    .cancel-button {
      float: left;
    }
  }
}

.team-agreement-rule-item-description {
  word-break: break-word;
}
