.container--search_wrapper {
  display: block;
  margin-bottom: 20px;
  
}

.container--search_page_heading {
  width: 100%;
  margin: 0 auto 24px auto;
}

.header--search_page_main {
  font-family: "Poppins";
  text-align: center;
  font-size: 36px;
  color: var(--font-color-dark);
  font-weight: 700;
  margin: 24px auto 0 auto;
  padding: 0;
}

.header--search_page_under {
  font-family: "Poppins";
  text-align: center;
  // font-size: 30px;
  color: var(--font-color-dark);
  font-weight: 700;
  margin: 24px auto 0 auto;
  padding: 0;
}

.header--search_page_secondary {
  font-size: 20px;
  color: var(--font-color-dark);
  font-weight: 500;
  margin: 12px auto 0 auto;
  text-align: center;
}

.container--search_bar_wrapper {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  justify-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto 32px auto;
  justify-content: center;
  @media screen and (max-width: 550px) {
    flex-direction:column-reverse;
  }
}

.filters {
  background-color: #00B790;
  color: white;
  // height: 56px;
  // width: 155px;
  border-radius: 6px;
  margin-left: 10px;
  outline: none;
  @media screen and (max-width: 550px) {
    margin: 10px;
  }
}

.filters * {
  color: white;
  outline: none;
  border-radius: 6px;
  border-color:transparent;
  
}

.search_input--wrapper * {
  height: 56px;
}

.search_input--wrapper {
  display: flex;
  align-items: center;
}

.search_bar_flex_box {
  display: flex;
  align-items: center;
}


.css-1xfc5k8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  color: white;
}

.container--search_page_results-top {
  width: 100%;
  display: block;
  margin: 0 auto 32px auto;
}

.container--search_page_results-bottom {
  @extend .container--search_page_results-top;
}

.text--search_page_query {
  color: var(--bg-blue);
  font-weight: 700;
}

.container--search_results-users {
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: start;
  align-items: center;
  row-gap: 16px;
  column-gap: 16px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat((3, 1fr));
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat((2, 1fr));
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat((1, 1fr));
  }
}

.container--search_results-teams {
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: start;
  align-items: center;
  row-gap: 16px;
  column-gap: 16px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat((3, 1fr));
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat((2, 1fr));
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat((1, 1fr));
  }
}

.container--user_display {
  box-sizing: border-box;
  width: 100%;
  height: 350px;
  padding: 5%;
  background-color: var(--bg-white);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.container--user_card_top {
  width: 100%;
  grid-column: 1 / -1;
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  column-gap: 2px;
  row-gap: 4px;
  height: 7rem;
}

.container--user_card_bottom {
  width: 100%;
  margin-top: 20px;

  .user_rating_data_name {
    font-weight: 700;
    margin-top: 10px;
  }

  .user_rating_data {
    word-break: break-all;
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
  }

  .review_unspecified {
    text-align: left;
    font-size: 14px;
    color: #808d87;
    margin-top: 10px;
    font-size: 16px;
  }

  .user_rating_review {
    word-break: break-all;
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;

    .review_rating_star {
      display: flex;
      text-align: left;
      color: #808d87;
      margin-top: 10px;

      .user_rating_found {
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    .user_total_rating {
      font-size: 17px;
      font-weight: 700;
      color: black;
      margin-top: 3.5px;
    }

    .user_total_rating_5 {
      font-size: 12px;
    }
  }
}

.user-card-message_me_button {
  font-family: Poppins !important;
  padding: 10px 20px;
  background-color: #2eb790;
  border-radius: 5px;
  color: white;
  width: 200px;
  font-size: 16px;
  border: 0;
  margin-top: 30px;
  cursor: pointer;
}

.svg--user_card_icon {
  width: 58px;
  height: 62px;
  grid-row: 1 / -1;
  grid-column: 1 / 2;
}

.image--search_avatar {
  height: 78px;
  width: 78px;
  grid-row: 1 / -1;
  grid-column: 1 / 2;
  border-radius: 50%;
}

.path--user_icon_path_one {
  fill: var(--bg-lightblue);
  stroke: var(--bg-lightbluetwo);
}

.path--user_icon_path_two {
  fill: var(--bg-lightbluetwo);
  stroke: var(--bg-lightbluethree);
}

.header--user_card_name {
  align-self: end;
  justify-self: left;
  grid-row: 1 / 2;
  grid-column: 2 / -1;
  font-size: 20px;
  font-weight: 700;
  color: var(--font-color-dark);
  margin: 0;
}

.header--user_card_bio {
  text-align: left;
  width: 100%;
  color: rgba(20, 121, 84, 0.7);
  font-size: 16px;
}

.header--user_card_skill {
  align-self: start;
  justify-self: left;
  grid-row: 2 / -1;
  grid-column: 2 / -1;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px 0;
}

// Styles for ratings on user cards on search page
.container--user_ratings {
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  justify-items: center;
  align-items: center;
  column-gap: 30px;

  @media screen and (max-width: 760px) {
    display: block;
  }

  .container--user_rating_card {
    width: 179px;
    height: 140px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-top: 4px solid #e67c89;
    margin: 0 0 15px 0;

    @media screen and (max-width: 760px) {
      max-width: 172px;
      margin: 1rem auto;
    }

    h5 {
      text-align: center;
    }

    .user_rating_icon {
      color: #ffb400;
    }
  }
}

.container--user_rating_one {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  row-gap: 4px;
}

.container--user_rating_two {
  @extend .container--user_rating_one;
  grid-column: 2 / -1;
  grid-row: 1 / -1;
}

.header--user_rating {
  font-size: 15px;
}

.text--user_rating {
  font-size: 15px;

  span {
    color: #4d5f58;
    font-size: 12px;
  }
}

.rates {
  text-align: center;
}

.button--invite {
  grid-column: 1 / -1;
}

.container--no_results {
  width: inherit;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin: 30px;
  gap: 30px;
  opacity: 0.5;
  color: var(--font-color-dark);
}
.divider{
  width: 80%;
  border-radius: 5px;
  height:4px;
  background-color: rgba($color: #a8a8a8, $alpha: 1.0)
}
// Teams
.container--search_results-teams {
  @extend .container--search_results-users;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat((3, 1fr));
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat((2, 1fr));
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat((1, 1fr));
  }
}

.button--result_wrapper {
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: var(--shadow-mid);
  transition: transform 0.25s ease-in, box-shadow 0.25s ease-in;

  &:focus {
    outline: var(--accent-main) auto 5px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-mid-hover);
  }
}

.container--team_display {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: auto auto 1fr 42px / 1fr;
  background-color: var(--bg-white);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 16px;
  row-gap: 8px;
  text-align: left;
  position: relative;
}

.container--team_card_top {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  width: 100%;
  justify-items: center;
  align-items: center;
}

.svg--team_card_icon {
  width: 60px;
  height: 60px;
  grid-row: 1 / -1;
  grid-column: 1 / 2;
}

.header--team_card_name {
  grid-column: 2 / -1;
  grid-row: 1 / -1;
  font-size: 20px;
  justify-self: start;
  color: var(--font-color-dark);
}

.container--team_card_bottom {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;

  p {
    vertical-align: bottom;
    font-size: 18px;
    color: rgba(0, 27, 17, 0.7);
  }
}

.header--team_card_data_label {
  font-size: 14px;
  margin: 0 0 4px 0;
  color: var(--font-color-dark);
  font-weight: 500;
}

.text--team_card_data {
  font-size: 16px;
  margin: 0px 0 8px 0;
  line-height: 1.5;
  color: var(--font-color-dark);
  font-family: var(--header-font);
  font-weight: 600;
}

.team-created {
  color: #586a7b;
  font-size: 14px;
  margin-left: 1rem;
}

.team-members {
  font-size: 14px;
}

.text--team_card_mission {
  overflow: hidden;
  max-height: 76px;
  font-size: 18px;
  vertical-align: bottom;
}

.button--team_apply {
  justify-self: center;

  padding: 8px 60px;
  width: 159px;
  height: 37px;
  border-radius: 24px;
  background-color: #2eb790 !important;
  color: #fff !important;
}

.button--team_apply-organization {
  justify-self: center;

  padding: 8px 60px;
  width: 90%;
  height: 37px;
  border-radius: 24px;
  background-color: #2eb790 !important;
  color: #fff !important;
}

// Styles for teams list modals
.container--users_teams {
  padding: 0;
  display: grid;
  justify-items: center;
  align-items: center;
  row-gap: 16px;
}

.header--users_teams_modal {
  justify-self: left;
  margin: 0 0 8px 0;
  font-weight: 400;
}

.container--users_team {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template: 1fr / 3fr 1fr;
  background-color: var(--bg-white);
  align-items: center;
  box-shadow: var(--shadow-high);
  padding: 4px;
  box-sizing: border-box;
  border-radius: 5px;
}

.header--users_team_title_modal {
  font-size: 18px;
  grid-column: 1 / 2;
  justify-self: left;
  margin: 16px 0 16px 8px;
}

.container--invite_error_wrapper {
  margin: 0 32px 32px 32px;
}

// Team Preview and apply styles
.apply--text_area {
  background-color: var(--bg-lightblue);
  -webkit-appearance: none;
}

.apply--button {
  margin: 16px auto 0 auto;
}

// User Preview
.user-preview {

  text-align: center;
  width: 100%;
}

.user-skill {
  display: inline-block;
  padding-right: 10px;

  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  padding: 5px 15px;
  border-bottom: 5px solid white;
  margin-right: 5px;

  &:nth-child(1) {
    background: #e7edff;
    color: #6380f1;
  }
  &:nth-child(2) {
    background: rgba(242, 138, 75, 0.1);
    color: #f28a4b;
  }
  &:nth-child(3) {
    background: rgba(0, 183, 144, 0.1);
    color: #2eb790;
  }
}

.user-skill_public {
  display: inline-block;
  padding-right: 10px;

  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  padding: 5px 15px;
  border-bottom: 5px solid white;
  margin-right: 2.5px;
  margin-left: 2.5px;

  &:nth-child(1) {
    // background: #d0dafd;
    // color: #6380f1;
    background: rgba(0, 183, 143, 0.219);
    color: #2eb790;
  }
  &:nth-child(2) {
    // background: rgba(242, 139, 75, 0.144);
    // color: #f28a4b;
    background: rgba(0, 183, 143, 0.219);
    color: #2eb790;
  }
  &:nth-child(3) {
    background: rgba(0, 183, 143, 0.219);
    color: #2eb790;
  }
}

.hr--dashboard_page_break {
  margin: 10px 0;
  background-color: #f5f4f2;
  height: 2px;
  border: none;
}

.user-preview-info {
  display: grid;
  grid-template-columns: 1fr 2fr;

  .grid-item {
    font-size: 18px;
    text-align: left;
    padding: 18px 0;
    font-weight: 700;
  }

  .user-preview-info_data {
    color: rgba(0, 27, 17, 0.7);
    font-weight: 300;
    word-break: break-all;
  }

  span {
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
  }
}

.user-preview_message-button {
  padding: 10px 20px;
  background-color: #2eb790;
  border-radius: 24px;
  color: white;
  width: 200px;
  font-size: 14px;
  border: 0;
  margin-top: 10px;
  cursor: pointer;
}
.user-preview_message-button-view-profile {
  padding: 10px 20px;
  background-color: #2eb790;
  border-radius: 24px;
  color: white;
  width: 200px;
  font-size: 14px;
  border: 0;
  margin-top: 10px;
  cursor: pointer;
  margin-left: 10px;

  @media screen and (max-width: 760px) {
    margin-left: 0px;
  }
}

.close-buttonResult {
  color: rgba(0, 27, 17, 0.1);
  position: absolute;
  margin: 2rem 3rem 0 0;
}

.text--user_rating {
  word-break: break-all;
}

.header--modal_heading-bio {
  color: rgba(0, 27, 17, 0.7);
  font-size: 16px;
}
.bkrnd {
  background-color: #f4f5f2;
}
